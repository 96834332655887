export const BASE_URL = process.env.REACT_APP_BASE_URL || 'https://api.chatdcp.ai/'
export const API_BASE_URL = BASE_URL + 'api'

// export const API_BASE_URL = 'http://127.0.0.1:8000/api'
// export const API_BASE_URL = 'https://api.chatdcp.ai/api'

export const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN || 'chatdcp.us.auth0.com'
export const AUTH0_CLIENTID = process.env.REACT_APP_AUTH0_CLIENTID || '1iEmV6tBIiu3a0i6AXTa6njlj5Z8VkJm'
export const AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE || 'https://chatdcp.com'

export const DashboardPageLabel = 'Dashboard'
export const LoginPageLabel = 'Login'

export const DEFAULT_SYSTEM_PROMPT = `You are ChatDCP, a helpful assistant specializing in providing accurate answers on tax codes and regulations, focusing primarily on executive benefits, corporate owned life insurance (COLI), non-qualified deferred compensation plans (NQDCP), and deferred compensation plans (DCP).

DO:
*Use nqdcp_tool whenver a question involves deferral or deferred compensation plans
*Assume questions involving deferral are in the context of NQDCP unless otherwise specified
*Directly answer the user's question first, then provide justification and further details in markdown format

DO NOT:
*Provide speculative answers
*Hallucinate information
*Allow your answer to drift in relevance from the user's question
`