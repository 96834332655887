import React, { useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faX } from '@fortawesome/free-solid-svg-icons';
import { useUser } from '../context/UserContext';
import { SourceDocument } from '../types';
import { API_BASE_URL } from '../constants';



interface DocumentsViewerProps {
    sources: { [sourceName: string]: string[] }
    documents: SourceDocument[]
    hidden: boolean
    hideSelf: () => void
}

const DocumentsViewer: React.FC<DocumentsViewerProps> =({
    sources,
    documents,
    hidden,
    hideSelf
}) => {

    const { user } = useUser();

    // A Map for caching documents: key = "source/docId", value = document data
    const cacheRef = useRef<Map<string, SourceDocument>>(new Map());

    // We'll store currently displayed documents in state
    const [currentDocs, setCurrentDocs] = useState<SourceDocument[]>([]);
    const [loading, setLoading] = useState(false);

    const filterAndSetDocuments = (documents: SourceDocument[]) => {
        const docMap = new Map<string, SourceDocument>();

        for (const doc of documents) {
            docMap.set(doc.name, doc);
        }

        // Convert Map values (unique documents) back to an array
        setCurrentDocs(Array.from(docMap.values()));
    };

    const fetchDocument = React.useCallback((source: string, docId: string): Promise<SourceDocument> => {
        const getDocument = async (source: string, docId: string): Promise<SourceDocument> => {
            const key = `${source}/${docId}`;

            // If it exists in cache, move it to the "most recently used" position
            const cachedDoc = cacheRef.current.get(key);
            if (cachedDoc) {
                // Delete and re-set to move it to the end (most recent) of the Map
                cacheRef.current.delete(key);
                cacheRef.current.set(key, cachedDoc);
                return cachedDoc;
            }
            // Otherwise, fetch from server
            const response = await fetch(
                `${API_BASE_URL}/sources/${key}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user?.accessToken || ''}`
                }
            });
            if (!response.ok) {
                throw new Error(`Failed to fetch document ${docId} from source ${source}`);
            }
            const documentData: SourceDocument = await response.json();

            // Insert new doc into cache. If above 50 items, remove the oldest
            if (cacheRef.current.size >= 50) {
                const oldestKey = cacheRef.current.keys().next().value; // first inserted key
                if (oldestKey)
                {
                    cacheRef.current.delete(oldestKey);
                }
            }
            cacheRef.current.set(key, documentData);
            return documentData;
        };
        return getDocument(source, docId);
    }, [user?.accessToken]);


    // Whenever `sources` changes, fetch all relevant documents
    useEffect(() => {

        const fetchDocuments = async () => {
            setLoading(true);
            try {
                const docs: SourceDocument[] = [];
                // Iterate over each source => document list
                for (const [sourceName, docIds] of Object.entries(sources)) {
                    for (const docId of docIds) {
                        const doc = await fetchDocument(sourceName, docId);
                        docs.push(doc);
                    }
                }
                filterAndSetDocuments(docs);
            } catch (error) {
                console.error('Error fetching documents:', error);
            } finally {
                setLoading(false);
            }
        };


        // If we were passed in documents just use those
        if (documents.length > 0) {
            console.log("setting documents!");
            console.log(documents);
            setCurrentDocs(documents);
            return;
        }
        // If we were passed sources instead we should query these for documents
        if (Object.keys(sources).length > 0) {
            console.log("Fetching documents!");
            fetchDocuments();
            return;
        } 
        // If we weren't passed anything in just clear docs
        else { 
            console.log("Emptying documents!");
            setCurrentDocs([]);
            return;
        }
    }, [fetchDocument, sources, documents]);

    return (
        <div
            // Absolutely position on the right; the parent must be `relative`
            className={`
                /* Position fixed so it stays pinned to the right edge of the window */
                fixed top-0 right-0 h-screen w-80 mt-16 z-10
        
                /* Basic styling */
                shadow-lg border-l border-slate-700 bg-slate-900
                overflow-y-auto
                text-white
        
                /* Transition for sliding in/out (translate) */
                transform transition-transform duration-300 ease-in-out
        
                /* Slide fully offscreen to the right if hidden; slide to x=0 if visible */
                ${hidden ? 'translate-x-full' : 'translate-x-0'}
            `}
        >
            {/* Header with "Sources" and close button */}
            <div className="sticky top-0 bg-slate-800 text-white px-4 py-3 flex justify-between items-center border-b border-slate-700">
                <h2 className="text-lg font-semibold">
                    {documents.length <= 0 ? "Citations" : "Uploaded Documents"}
                </h2>
                <button
                    onClick={hideSelf}
                    className='text-xs text-gray-200 hover:text-gray-500 w-4 h-4'
                >
                    <FontAwesomeIcon icon={faX} className='w-full h-full'/>
                </button>
            </div>
            { loading ? (
                <div className='flex justify-center items-center mt-16'>
                    <FontAwesomeIcon
                        icon={faSpinner}
                        className="animate-spin text-gray-400 text-3xl"
                    />
                </div>
            ) : currentDocs.map((doc) => (
                <div key={doc.id} className="p-4 border-b border-slate-700">
                    <h2 className="text-white font-semibold break-words">{doc.name}</h2>
                    {/* <p className="text-gray-300 text-sm">{doc.id}</p> */}
                </div>
            ))}
        </div>
    );
}

export default DocumentsViewer