import { useState } from "react";
import React from "react";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useConversation } from "../context/ConversationContext";

const ConversationToolSelector: React.FC = () => {
    const [open, setOpen] = useState(false);

    const { availableTools, selectedConversation, createNewConversation } = useConversation();

    const getToolName = (): string => {
        if (selectedConversation)
            return availableTools[selectedConversation.tool];
        else
            return ""
    }

    return (
        <div className="relative inline-block text-left w-44">
            <button
                onClick={() => setOpen(!open)}
                className="flex items-center gap-2 px-3 py-2 rounded-md transition-colors 
                        hover:bg-gray-700 focus:outline-none"
            >
                <span className="text-gray-200">
                    {getToolName()}
                </span>
                <FontAwesomeIcon icon={faChevronDown} className="h-3 w-3 text-gray-500" />
            </button>
        
            {open && (
                <div className="absolute left-0 mt-2 bg-gray-900 border border-gray-700 
                                rounded-md shadow-lg z-10">
                    <ul className="py-1">
                        {availableTools.map((option, index) => (
                        <li
                            key={index}
                            onClick={() => {
                                createNewConversation(index, null);
                                setOpen(false);
                            }}
                            className="px-4 py-2 text-white dark:text-white bg-gray-900
                                    hover:bg-gray-800 cursor-pointer transition-colors"
                        >
                            {option}
                        </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default ConversationToolSelector;