// Import necessary React components and types
import React from 'react'
import MessagesContainer from './MessagesContainer'
import ChatInput from './ChatInput'
import { useUser } from '../context/UserContext'
import { useConversation } from '../context/ConversationContext'


const ConversationWindow: React.FC = () => {
    const {user} = useUser()

    const { selectedConversation } = useConversation();

    // Render the ConversationWindow component
    return (
        <div
            className={`grow w-5/6 pb-4 pt-4 pl-4 col-12 ${
                !user && 'pointer-events-none'
            }`}
        >
            <div className='flex flex-col h-full items-center justify-center max-w-screen-md mx-auto'>
                {/* Messages container that shows all messages in the conversation */}
                <MessagesContainer/>
                {/* Chat input component to type and send messages */}
                {selectedConversation && (
                    <ChatInput/>
                )}
            </div>
        </div>
    )
}

export default ConversationWindow
