import React, { useCallback, useEffect, useRef, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useUser } from '../../context/UserContext';
import { API_BASE_URL } from '../../constants';

const PromptManagement: React.FC  = () => {

    const { user } = useUser();

    const [selectedTool, setSelectedTool] = useState<number>(0);
    const [availableTools, setAvailableTools] = useState<[string]>([""]);

    // fetch list of available tables
    useEffect(() => {
        // fetch list of tools

        const fetchToolList = async () => {
            const fileResponse = await fetch(
                `${API_BASE_URL}/dashboard/tables`,
                {
                    headers: {
                        'Authorization': `Bearer ${user?.accessToken}`,
                    },
                }
            );

            if (!fileResponse.ok) {
                throw new Error("failed to fetch tables");
            }

            const fileDataJson = await fileResponse.json();
            setAvailableTools(fileDataJson);
        }
        fetchToolList();
    }, [user]);


    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [currentPrompts, setCurrentPrompts] = useState<string[]>(["", "", "", ""]);
    const [currentQueryDescriptions, setCurrentQueryDescriptions] = useState<string[]>(["", "", "", ""]);
    const [currentPromptText, setCurrentPromptText] = useState<string>("");
    const [currentQueryDescriptionText, setCurrentQueryDescriptionText] = useState<string>("");

    // fetches current prompts and descriptions for all tools
    const fetchCurrents = useCallback(async () => {
        setIsLoading(true);

        const promptResponse = await fetch(
            `${API_BASE_URL}/dashboard/system_prompts`,
            {
                headers: {
                    'Authorization': `Bearer ${user?.accessToken}`,
                },
            }
        );

        if (!promptResponse.ok) {
            throw new Error("failed to fetch tables");
        }

        const promptDataJSON = await promptResponse.json();
        setCurrentPrompts(promptDataJSON);

        const descriptionResponse = await fetch(
            `${API_BASE_URL}/dashboard/query_tool_descriptions`,
            {
                headers: {
                    'Authorization': `Bearer ${user?.accessToken}`,
                },
            }
        );

        if (!descriptionResponse.ok) {
            throw new Error("failed to fetch tables");
        }

        const queryDataJSON = await descriptionResponse.json();
        console.log(queryDataJSON);
        setCurrentQueryDescriptions(queryDataJSON);

        setIsLoading(false);

    }, [user?.accessToken])

    // Effect wrapper for fetchCurrents
    useEffect(() => {
        fetchCurrents();
    }, [fetchCurrents])

    // Whenever the state is changed set text and resize
    useEffect(() => {
        setCurrentPromptText(currentPrompts[selectedTool]);
        setCurrentQueryDescriptionText(currentQueryDescriptions[selectedTool]);
    }, [currentPrompts, currentQueryDescriptions, selectedTool])

    useEffect(() => {
        autoResize(promptRef.current);
        autoResize(descriptionRef.current);
    }, [currentPromptText, currentQueryDescriptionText])

    // Commit values to db if they've changed and trigger a resync
    const handleCommit = useCallback(async () => {
        let didUpdate = false;

        if (currentPromptText !== currentPrompts[selectedTool])
        {
            const formData = new FormData();
            formData.append("tool_selection", String(selectedTool));
            formData.append("text", currentPromptText);

            await fetch(
                `${API_BASE_URL}/dashboard/system_prompt`,
                {
                    headers: {
                        'Authorization': `Bearer ${user?.accessToken}`,
                    },
                    method: 'PUT',
                    body: formData
                }
            );

            didUpdate = true;
        }
        if (currentQueryDescriptionText !== currentQueryDescriptions[selectedTool])
        {
            const formData = new FormData();
            formData.append("tool_selection", String(selectedTool));
            formData.append("text", currentPromptText);

            await fetch(
                `${API_BASE_URL}/dashboard/query_tool_description`,
                {
                    headers: {
                        'Authorization': `Bearer ${user?.accessToken}`,
                    },
                    method: 'PUT',
                    body: formData
                }
            );

            didUpdate = true;
        }

        if (didUpdate)
        {
            fetchCurrents();
        }
    }, [currentPromptText, currentPrompts, currentQueryDescriptionText, currentQueryDescriptions, fetchCurrents, selectedTool, user?.accessToken]);

    const promptRef = useRef<HTMLTextAreaElement>(null);
    const descriptionRef = useRef<HTMLTextAreaElement>(null);

    const autoResize = (element: HTMLTextAreaElement | null) => {
        if (element)
        {
            const minHeight = 80;  // in px
            const maxHeight = 450; // in px
            element.style.height = 'auto'; // Reset to auto to allow shrinking
            const scrollHeight = element.scrollHeight;
            const clampedHeight = Math.max(minHeight, Math.min(scrollHeight, maxHeight));
            element.style.height = `${clampedHeight}px`;
        }
    };

    return (
        <React.Fragment>
            <div className='p-6'>

                {/* Button & Dropdown Container */}
                <div className="flex items-center bg-slate-800 text-gray-200 border border-gray-700 rounded-t">
                    <select 
                        className="bg-slate-800 text-white px-4 py-2 mr-2 outline-none" 
                        onChange={(e) => setSelectedTool(parseInt(e.target.value, 10))} // Convert value to integer
                    >
                        {availableTools.map((tableName, index) => (
                            <option key={index} value={index}>
                                {tableName}
                            </option>
                        ))}
                    </select>
                </div>
                
                {isLoading ? (
                    <div className="flex items-center justify-center h-32">
                        <FontAwesomeIcon
                            icon={faSpinner}
                            className="text-blue-500 text-4xl animate-spin"
                        />
                    </div>
                ) : (
                    <div className="border border-gray-700 rounded-b p-6 space-y-4">
                        <div>
                            <label className="block text-sm font-medium text-gray-200 mb-1">System Prompt</label>
                            <textarea
                                ref={promptRef}
                                value={currentPromptText}
                                onChange={(e) => setCurrentPromptText(e.target.value)}
                                className="w-full h-auto px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 whitespace-pre-wrap"
                                placeholder="Enter system prompt for tool"
                                // onInput={(e) => {autoResize(e.currentTarget)}}
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-200 mb-1">Query Engine Description</label>
                            <textarea
                                ref={descriptionRef}
                                value={currentQueryDescriptionText}
                                onChange={(e) => setCurrentQueryDescriptionText(e.target.value)}
                                className="w-full min-h-[4rem] h-auto px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 whitespace-pre-wrap"
                                placeholder="Enter query engine tool description for tool"
                                // onInput={(e) => {autoResize(e.currentTarget)}}
                            />
                        </div>
                        <div className="pt-4">
                            <button
                                onClick={handleCommit}
                                className="bg-blue-600 text-white px-4 py-2 rounded-md bg-orange-600 hover:bg-orange-700 transition"
                            >
                                Commit
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </React.Fragment>
    );
};

export default PromptManagement;