import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faSpinner, faTrashAlt, faEllipsis } from "@fortawesome/free-solid-svg-icons";
import { useConversation } from "../context/ConversationContext";
import Modal from "./Modal";



const ChatSidebar: React.FC = () => {

    const { conversationsList, selectedConversation, setSelectedConversation, renameConversation, deleteConversation } = useConversation();

    // State for managing the editing input field
    const [currentlyEditedConversation, setCurrentEditId] = useState<number | null>(null);

    const [editingName, setEditingName] = useState("");
    const editingInputRef = useRef<HTMLInputElement>(null);
    const [openMenuId, setOpenMenuId] = useState<number | null>(null); // Track which menu is open
    const menuRef = useRef<HTMLDivElement | null>(null);
    const buttonRef = useRef<HTMLButtonElement | null>(null); // Ref for the ellipsis button

    // Tooltip state and timeout reference
    const [hoveredConversationId, setHoveredConversationId] = useState<number | null>(null);
    const hoverTimeoutRef = useRef<NodeJS.Timeout | null>(null);

    // Auto-focus input field when editing a conversation
    useEffect(() => {
        if (currentlyEditedConversation) {
            editingInputRef.current?.focus();
        }
    }, [currentlyEditedConversation]);

    // Initiate editing for a conversation
    const handleEditConversationName = (conversation: { id: number; name: string }) => {
        setCurrentEditId(conversation.id);
        setEditingName(conversation.name);
        setOpenMenuId(null); // Close menu when editing
    };

    // Cancel editing mode
    const handleCancelEdit = () => {
        setCurrentEditId(null);
        setEditingName("");
    };

    // Save edited conversation name
    const handleSaveEdit = () => {
        if (currentlyEditedConversation !== null) {
            renameConversation(currentlyEditedConversation, editingName);
            handleCancelEdit();
        }
    };

    // Close the menu when clicking outside
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                menuRef.current &&
                !menuRef.current.contains(event.target as Node) &&
                buttonRef.current &&
                !buttonRef.current.contains(event.target as Node)
            ) {
                setTimeout(() => setOpenMenuId(null), 100); // Small delay to prevent instant re-open
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);


    const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
    const [currentDeletionId, setCurrentDeletionId] = useState<number>(-1);

    function handleCloseDeleteModal(): void {
        setDeleteModalOpen(false);
        setCurrentDeletionId(-1);
    }

    function handleConfirmDelete(): void {
        deleteConversation(currentDeletionId);
        handleCloseDeleteModal();
    }

    return (
        <div className="p-4 w-full min-w-64">
            {conversationsList.map((conversation) => (
                <React.Fragment key={conversation.id}>
                    {(conversation.name !== "New Chat" || conversation.hasUserMessage) ? (
                        <div
                            className={`flex items-center my-1 rounded transition duration-200 ease-in-out cursor-pointer relative group ${
                                conversation.id === selectedConversation?.id
                                    ? "bg-gray-600 shadow font-semibold"
                                    : "hover:bg-gray-700 font-normal hover:shadow-md"
                            }`}
                            onMouseEnter={() => {
                                hoverTimeoutRef.current = setTimeout(() => {
                                    setHoveredConversationId(conversation.id);
                                }, 700); // Delay tooltip by 1 second
                            }}
                            onMouseLeave={() => {
                                if (hoverTimeoutRef.current) {
                                    clearTimeout(hoverTimeoutRef.current);
                                }
                                setHoveredConversationId(null);
                            }}
                        >
                            {currentlyEditedConversation === conversation.id ? (
                                // Editing mode (input field)
                                <div className="w-full px-2 py-1 bg-gray-800 rounded-md border border-blue-500 shadow-md transition-transform duration-200 overflow-hidden">
                                    {/* Editable Input */}
                                    <input
                                        ref={editingInputRef}
                                        type="text"
                                        value={editingName}
                                        onChange={(e) => setEditingName(e.target.value)}
                                        className="w-full px-2 py-1 text-sm rounded-md text-gray-200 bg-gray-900 border border-gray-700 focus:ring-2 focus:ring-blue-500 focus:outline-none transition-all duration-200"
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                e.preventDefault(); // Prevent unintended form submissions
                                                handleSaveEdit();
                                            } else if (e.key === "Escape") {
                                                handleCancelEdit();
                                            }
                                        }}
                                        onBlur={handleCancelEdit} // Clicking outside cancels edit
                                        placeholder="Rename conversation..."
                                        autoFocus
                                    />
                                </div>
                            ) : (
                                <div className="relative w-full">
                                    {/* Select Conversation */}
                                    <button
                                        onClick={() => setSelectedConversation(conversation)}
                                        className="group flex items-center justify-between w-full text-left px-3 py-1 rounded-md 
                                            text-gray-200 hover:bg-gray-700 transition-colors duration-150"
                                    >
                                        <span 
                                            className={`w-2 h-2 rounded-full mr-2 flex-shrink-0 
                                                ${conversation.tool === 0 ? "bg-blue-500" :
                                                conversation.tool === 1 ? "bg-amber-500" :
                                                conversation.tool === 2 ? "bg-purple-500" : "bg-[#fd7e14]"}`}
                                        ></span>
                                        {/* Conversation Name & Throbber */}
                                        <div className="flex items-center w-full overflow-hidden relative">
                                            <p 
                                                className="flex-1 whitespace-nowrap overflow-hidden text-sm"
                                                style={{
                                                    maskImage: "linear-gradient(to right, rgba(255,255,255,1) 85%, rgba(255,255,255,0))",
                                                    WebkitMaskImage: "linear-gradient(to right, rgba(255,255,255,1) 85%, rgba(255,255,255,0))",
                                                }}
                                            >
                                                {conversation.name}
                                            </p>
                                            {conversation.isWaitingForName && (
                                                <FontAwesomeIcon
                                                    icon={faSpinner}
                                                    className="ml-2 animate-spin text-gray-400"
                                                />
                                            )}
                                        </div>

                                        {/* Delayed Tooltip Showing Full Name */}
                                        {hoveredConversationId === conversation.id && (
                                            <div
                                                className="absolute left-0 top-full z-10 bg-gray-900 text-white text-sm px-2 py-1 rounded-md shadow-md 
                                                    opacity-100 transition-opacity duration-200 pointer-events-none"
                                            >
                                                {conversation.name}
                                            </div>
                                        )}

                                        {/* Context Menu Button */}
                                        <button
                                            ref={buttonRef}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setOpenMenuId(openMenuId === conversation.id ? null : conversation.id);
                                            }}
                                            className="opacity-0 group-hover:opacity-100 transition-opacity duration-150 text-gray-400 hover:text-gray-200"
                                        >
                                            <FontAwesomeIcon icon={faEllipsis} />
                                        </button>
                                    </button>

                                    {/* Context Menu (Dropdown) */}
                                    {openMenuId === conversation.id && (
                                        <div ref={menuRef} className="absolute right-0 mt-1 w-32 bg-gray-800 shadow-md rounded-md z-10">
                                            <button
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleEditConversationName(conversation);
                                                }}
                                                className="flex w-full px-3 py-2 text-sm text-gray-300 hover:bg-gray-700 rounded-t-md"
                                            >
                                                <FontAwesomeIcon icon={faEdit} className="mr-2" />
                                                Edit
                                            </button>
                                            <button
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setCurrentDeletionId(conversation.id);
                                                    setDeleteModalOpen(true);
                                                }}
                                                className="flex w-full px-3 py-2 text-sm text-red-500 hover:bg-gray-700 rounded-b-md"
                                            >
                                                <FontAwesomeIcon icon={faTrashAlt} className="mr-2" />
                                                Delete
                                            </button>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    ) : null}
                </React.Fragment>
            ))}
            <Modal
                isOpen={isDeleteModalOpen}
                onClose={handleCloseDeleteModal}
                onConfirm={handleConfirmDelete}
                confirmColor='bg-red-500'
                confirmColorHover='hover:bg-red-700'
            >
                <p>
                    Are you sure you want to delete{' '}
                    {conversationsList.find(
                            (conv) => conv.id === currentDeletionId
                        )?.name}
                    ?
                </p>
            </Modal>
        </div>
    );
};

export default ChatSidebar;
