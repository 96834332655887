import React, { useState } from 'react';
import StatusModal from '../../StatusModal';
import { API_BASE_URL } from '../../../constants';
import { useUser } from '../../../context/UserContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

// Define the props expected by the UploadFiles component
interface UploadFilesProps {
    dataTableSelection: number
    dataTableName: string
    closeSelf: () => void
    conversation_id?: number
}

const DocumentUpload: React.FC<UploadFilesProps> = ({
    dataTableSelection,
    dataTableName,
    closeSelf,
    conversation_id = -1
}) => {
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

    const { user } = useUser()

    const [uploadModalState, setUploadModalState] = useState<number>(0); // 0 is closed, 1 is uploading, 2 is success, 3 is failure

    const [duplicateFileNames, setDuplicateFileNames] = useState<string[]>([]);
    const [waitingForDupeCheck, setWaitingForDupeCheck] = useState<boolean>(false);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const filesArray = Array.from(event.target.files);
            checkForDuplicates(filesArray);
            setSelectedFiles(filesArray);
        }
    };

    const handleFileRemove = (index: number) => {
        const updatedFiles = selectedFiles.filter((_, i) => i !== index);
        checkForDuplicates(updatedFiles);
        setSelectedFiles(updatedFiles);
    };

    const handleRequestUpload = () => {
        if (selectedFiles.length === 0) {
            alert("No files selected.");
            return;
        }
        
        setUploadModalState(1);
        handleUpload(selectedFiles);
    };

    const handleRequestUploadNoDuplicates = () => {
        if (selectedFiles.length === 0) {
            alert("No files selected.");
            return;
        }
        
        setUploadModalState(1);
        // Filter out files that have a name matching duplicateFileNames (ignoring extensions)
        const filesToUpload = selectedFiles.filter(file => {
            const fileNameWithoutExtension = file.name.replace(/\.[^/.]+$/, "");
            return !duplicateFileNames.includes(fileNameWithoutExtension);
        });
        console.log(filesToUpload);
        handleUpload(filesToUpload);
    }

    const checkForDuplicates = async (filesArray: File[]) => {
        
        setWaitingForDupeCheck(true);

        try {
            const formData = new FormData();
            // Append file names to formdata
            for (let i = 0; i < filesArray.length; i++) {
                formData.append("file_names", filesArray[i].name);
            }

            let api_url = `${API_BASE_URL}/dashboard/documents/dupcheck`

            if (conversation_id < 0) {
                // Append table selection
                formData.append("data_table_selection", String(dataTableSelection));
            }
            else {
                // Append conversation_id
                formData.append("conversation_id", String(conversation_id));
                api_url = `${API_BASE_URL}/conversation/documents/dupcheck`
            }

            const response = await fetch(api_url, {
                method: "POST",
                body: formData,
                headers: {
                    'Authorization': 'Bearer ' + user?.accessToken,
                }
            });

            if (!response.ok) {
                throw new Error(`Server error: ${response.statusText}`);
            }

            const result = await response.json();

            console.log("logging dupcheck results");
            console.log(result);

            setDuplicateFileNames(result);

        } catch (error) {
            console.error("Error checking for duplicates:", error);
            setDuplicateFileNames([]);

        } finally {
            setWaitingForDupeCheck(false);
        }
    }

    async function handleUpload(files: File[]) {
        if (files.length === 0) {
            // alert("No Files!")
            setUploadModalState(0);
            return;
        }

        // Fire API call
        const formData = new FormData();

        // Append files to formData
        for (let i = 0; i < files.length; i++) {
            formData.append("files", files[i]);
        }

        let api_url = `${API_BASE_URL}/dashboard/documents/upload`

        if (conversation_id < 0) {
            // Append table selection
            formData.append("data_table_selection", String(dataTableSelection));
        }
        else {
            // Append conversation_id
            formData.append("conversation_id", String(conversation_id));
            api_url = `${API_BASE_URL}/conversation/documents/upload`
        }

        // Append dataTableSelection as a form field
        formData.append("data_table_selection", String(dataTableSelection));

        try {
            const response = await fetch(api_url, {
                method: "PUT",
                body: formData,
                headers: {
                    'Authorization': 'Bearer ' + user?.accessToken,
                }
            });

            if (!response.ok) {
                throw new Error(`Server error: ${response.statusText}`);
            }

            console.log("file upload api call ran");

            // const result = await response.json();
            // console.log("Upload successful", result);
            if (uploadModalState === 0) {
                setUploadModalState(2);
                console.log("setting modal state to 2");
            }
            else {
                console.log("successful run modal was already closed!");
            }
        } catch (error) {
            console.error("Error uploading files:", error);
            if (uploadModalState === 0) {
                setUploadModalState(3);
                console.log("setting modal state to 3");
            }
            else {
                console.log("failed run modal was already closed!");
            }
        }
    }

    const handleUploadModalClose = () => {
        setUploadModalState(0);
        closeSelf();
    };

    return (
        <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50'>
            <div className="min-h-screen flex flex-col items-center justify-center p-4">
                
                <div className="bg-white shadow-md rounded-lg p-6 pt-8 w-full max-w-lg relative">
                    {/* Close Button - Positioned in Top Right */}
                    <button
                        onClick={closeSelf}
                        className="absolute top-2 right-3 text-red-500 hover:text-red-700 transition duration-200"
                    >
                        <FontAwesomeIcon icon={faTimes} className="text-lg" />
                    </button>
                    
                    <h1 className="text-2xl font-bold text-gray-800 mb-4">Upload Documents to {dataTableName} </h1>
                    <label
                        htmlFor="fileInput"
                        className="flex items-center justify-center w-full p-4 border-2 border-dashed border-gray-300 rounded-lg cursor-pointer hover:bg-gray-50"
                    >
                        <span className="text-gray-600">Click to select pdf/csv files</span>
                        <input
                            id="fileInput"
                            type="file"
                            multiple
                            accept='.pdf, .csv, .txt'
                            className="hidden"
                            onChange={handleFileChange}
                        />
                    </label>
                    {selectedFiles.length > 0 && (
                        <div className="mt-4">
                            <h2 className="text-lg font-semibold text-gray-700 mb-2">Selected Files:</h2>
                            <ul className="space-y-2">
                                {selectedFiles.map((file, index) => (
                                    <li
                                        key={index}
                                        className="flex justify-between items-center bg-gray-50 p-2 rounded border border-gray-300"
                                    >
                                        <span className="text-sm text-gray-800">{file.name}</span>
                                        <button
                                            className="text-red-500 hover:text-red-700 text-sm font-semibold"
                                            onClick={() => handleFileRemove(index)}
                                        >
                                            Remove
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                    {/* Display Duplicate Files Warning */}
                    {duplicateFileNames.length > 0 && (
                        <div className="mt-4 bg-red-100 border border-red-400 text-red-700 p-3 rounded-lg">
                            <h2 className="text-lg font-semibold">Duplicate Files Found:</h2>
                            <ul className="mt-2 space-y-1">
                                {duplicateFileNames.map((fileName, index) => (
                                    <li key={index} className="text-sm font-medium">
                                        {fileName}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}

                    {/* Button row */}
                    {/* <div>
                        <button
                            onClick={handleRequestUpload}
                            className="mt-4 w-full bg-blue-600 text-white py-2 rounded-lg hover:bg-blue-700 transition duration-200"
                        >
                            Upload Files
                        </button>
                    </div> */}
                    <div className="mt-4 w-full">
                        {selectedFiles.length > 0 &&
                            (waitingForDupeCheck ? (
                                // Show a disabled Upload button while waiting
                                <button
                                    disabled
                                    className="w-full bg-gray-400 text-white py-2 rounded-lg cursor-not-allowed"
                                >
                                    Checking for duplicates...
                                </button>
                            ) : ((duplicateFileNames.length === selectedFiles.length) && duplicateFileNames.length > 0) ? (
                                // If all selected files are duplicates, show only "Overwrite All"
                                <button
                                    onClick={handleRequestUpload}
                                    className="w-full bg-red-600 text-white py-2 rounded-lg hover:bg-red-700 transition duration-200"
                                >
                                    Overwrite All
                                </button>
                            ) : duplicateFileNames.length > 0 ? (
                                // Show Overwrite and Skip Duplicates buttons if duplicates exist
                                <div className="flex flex-col sm:flex-row gap-2">
                                    <button
                                        onClick={handleRequestUpload}
                                        className="flex-1 bg-red-600 text-white py-2 rounded-lg hover:bg-red-700 transition duration-200"
                                    >
                                        Overwrite Duplicates
                                    </button>
                                    <button
                                        onClick={handleRequestUploadNoDuplicates}
                                        className="flex-1 bg-yellow-500 text-white py-2 rounded-lg hover:bg-yellow-600 transition duration-200"
                                    >
                                        Skip Duplicates
                                    </button>
                                </div>
                            ) : (
                                // Show only Upload button if no duplicates
                                <button
                                    onClick={handleRequestUpload}
                                    className="w-full bg-blue-600 text-white py-2 rounded-lg hover:bg-blue-700 transition duration-200"
                                >
                                    Upload Files
                                </button>
                            ))
                        }
                    </div>
                </div>
            </div>

            <StatusModal
                modalState={uploadModalState} 
                onClose={handleUploadModalClose}
                loadingText='Uploading...'
            />
        </div>
    );
};
    
export default DocumentUpload;
