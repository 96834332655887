import { useAuth0 } from "@auth0/auth0-react";
import { Navigate, Route, Routes } from 'react-router-dom'
import React from 'react'
import Main from "./Main";
import Login from "./Login";
import DashboardContainer from "./AdminDashboard/DashboardContainer";
import { DashboardPageLabel, LoginPageLabel } from "../constants";
import SwaggerDocs from "./SwaggerDocs";


const DashboardPagePath = "/" + DashboardPageLabel;
const LoginPagePath = "/" + LoginPageLabel;

function Container() {
    const { isLoading, isAuthenticated} = useAuth0();

    return (
    <Routes>
        <Route
            path='/'
            element={
                (isLoading || isAuthenticated ?
                    <Main /> : <Navigate to={LoginPagePath}  />)}
        />
        <Route
            path='/login'
            element={<Login />}
        />
        <Route
            path='/dashboard'
            element={
                (isLoading || isAuthenticated ?
                    <DashboardContainer /> : <Navigate to={LoginPagePath} />)}
        />
        <Route
            path='/docs'
            element={
                (isLoading || isAuthenticated ?
                    <SwaggerDocs /> : <Navigate to={LoginPagePath} />)}
        />
    </Routes>)
}

export default Container