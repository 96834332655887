import { useState, useEffect } from "react";
import { useUser } from "../../context/UserContext";
import React from "react";
import { API_BASE_URL } from "../../constants";



interface TokenSums {
    total_embedding_token_count: number;
    prompt_llm_token_count: number;
    completion_llm_token_count: number;
    total_llm_token_count: number;
}

const TokenViewer = () => {

    const {user} = useUser();

    const [tokenSums, setTokenSums] = useState<TokenSums | null> (null);

    useEffect(() => {

        fetch(`${API_BASE_URL}/dashboard/tokens`, {
            headers: {
                'Authorization': `Bearer ${user?.accessToken}`,
            },
        })  // Adjust API URL as needed
            .then(response => response.json())
            .then(data => {
                setTokenSums(data);
            })
            .catch(error => {
                console.error("Error fetching token sums:", error);
            });
    }, [user]);

    

    return (
        (tokenSums === null) ? 
        ( <div className="flex items-center justify-center h-screen text-xl text-white">Loading...</div> ) :
        (
            <div className="flex flex-col items-center justify-center h-screen text-white">
                <h2 className="text-2xl font-semibold mb-4">Token Counts</h2>
                <div className="grid grid-cols-2 gap-4 p-6 bg-gray-800 rounded-lg shadow-lg">
                    <div className="text-lg font-medium">total_embedding_token_count:</div>
                    <div className="text-lg">{tokenSums.total_embedding_token_count}</div>
                    
                    <div className="text-lg font-medium">prompt_llm_token_count:</div>
                    <div className="text-lg">{tokenSums?.prompt_llm_token_count}</div>
                    
                    <div className="text-lg font-medium">completion_llm_token_count:</div>
                    <div className="text-lg">{tokenSums?.completion_llm_token_count}</div>
                    
                    <div className="text-lg font-medium">total_llm_token_count:</div>
                    <div className="text-lg">{tokenSums?.total_llm_token_count}</div>
                </div>
            </div>
        )
    );
};

export default TokenViewer;
