import React, { useEffect, useState } from 'react';
import { useUser } from '../../context/UserContext';
import { jwtDecode } from 'jwt-decode';
import Dashboard from './Dashboard';


const DashboardContainer = () => {

    const { user } = useUser()

    const [hasPermissions, setHasPermissions] = useState(0) // 0 is loading, 1 is auth and 2 is auth failure

    // Check permissions on mount
    useEffect(() => {
        const token = user?.accessToken;
        if (token)
        {
            const decodedToken = jwtDecode(token) as Record<string, any>;
            const permissions = decodedToken['permissions'];

            if (permissions.includes('admindashboard'))
            {
                setHasPermissions(1);
            }
            else
            {
                setHasPermissions(2);
            }
        }
        else
        {
            setHasPermissions(0); // invalid token means loading
        }
    }, [user?.accessToken])

    return (
        <React.Fragment>
            {hasPermissions === 0 ? (
                // Loading screen when the state is 0
                <div className='flex items-center justify-center h-screen bg-slate-900'>
                    <div className="flex space-x-2">
                        <div className="w-4 h-4 bg-white rounded-full animate-bounce"></div>
                        <div className="w-4 h-4 bg-white rounded-full animate-bounce delay-75"></div>
                        <div className="w-4 h-4 bg-white rounded-full animate-bounce delay-150"></div>
                    </div>
                </div>
            ) : (
                <React.Fragment>
                    {hasPermissions === 1 ? (
                        // Actual dashboard page
                        <Dashboard/>
                    ) : (
                        // Unauthorized state (shouldn't reach this unless someone who doesn't have access manually enters the /dashboard url)
                        <span> You shouldn't be here dude! </span>
                    )}
                </React.Fragment>
            )}
            
        </React.Fragment>
    );
};

export default DashboardContainer;