import React, { useEffect, useState } from 'react';
import UserManagement from './UserManagement/User';
import DocumentManagement from './DocumentManagement/DocumentManagement';
import MenuBar from '../MenuBar';
import SideBar from '../Sidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faFile, faUsers } from '@fortawesome/free-solid-svg-icons';
import SwaggerDocs from '../SwaggerDocs';
import { useUser } from '../../context/UserContext';
import { jwtDecode } from 'jwt-decode';
import TokenViewer from './TokenViewer';
import PromptManagement from './PromptManagement';


// If we're here we're assuming the user has the necessary perms otherwise they would've been stopped by DashboardContainer

const Dashboard = () => {

    // const { user } = useUser()

    const [ currentTab, setCurrentTab] = useState(0) // 0 is dashboard, 1 is users, 2 is documents

    const renderContent = () => {
        switch (currentTab) {
            case 0:
                return <DocumentManagement/>;
            case 1:
                return <UserManagement/>;
            case 2:
                return <SwaggerDocs/>;
            case 3:
                return <TokenViewer/>;
            case 4:
                return <PromptManagement/>;

            default:
                return null;
        }
    }

    const getPageLabel = () => {
        switch (currentTab) {
            case 0:
                return "Document Management";
            case 1:
                return "User Management";
            case 2:
                return "ChatDCP API";
            case 3:
                return "Token Count";
            case 4:
                return "Prompt Management"
            default:
                return null;
        }
    }

    useEffect(() => { document.body.classList.add("bg-slate-900"); return () => {document.body.classList.remove("bg-slate-900");} }, [])

    const {user} = useUser()
    const [canViewDocs, setViewDocs] = useState(false)
    const [canViewPrompt, setViewPrompt] = useState(true)
    
    // Effect hook to check for admin perms when component mounts
    useEffect(() => {
        const token = user?.accessToken;
        if (token)
        {
            const decodedToken = jwtDecode(token) as Record<string, any>;
            const permissions = decodedToken['permissions'];

            setViewDocs(permissions.includes('apidocumentation'));
            setViewPrompt(permissions.includes("promptmanager"));
        }
        else
        {
            setViewDocs(false); // invalid token means no access
        }
    }, [user?.accessToken])

    return (
        <div className='flex w-screen'>
            <div>
                <SideBar
                    header = {
                        <div className="flex items-center justify-center h-16 border-b border-gray-700 text-white min-w-64">
                            <h1 className="text-xl font-bold">Admin Panel</h1>
                        </div>
                    }

                    body = {
                        <div className="flex flex-col p-4 space-y-4">
                            <button onClick={() => setCurrentTab(0)} className="flex items-center px-4 py-2 text-gray-300 bg-gray-700 rounded hover:bg-gray-600 hover:text-white space-x-2">
                                <div className="w-6 h-6 flex items-center justify-center">
                                    <FontAwesomeIcon icon={faFile}/>
                                </div>
                                <span>Documents</span>
                            </button>
                            <button onClick={() => setCurrentTab(1)} className="flex items-center px-4 py-2 text-gray-300 bg-gray-700 rounded hover:bg-gray-600 hover:text-white space-x-2">
                                <div className="w-6 h-6 flex items-center justify-center">
                                    <FontAwesomeIcon icon={faUsers}/>
                                </div>
                                <span>Users</span>
                            </button>
                            {canViewDocs && (
                                <React.Fragment>
                                    <button onClick={() => setCurrentTab(2)} className="flex items-center px-4 py-2 text-gray-300 bg-gray-700 rounded hover:bg-gray-600 hover:text-white space-x-2">
                                        <div className="w-6 h-6 flex items-center justify-center">
                                            <FontAwesomeIcon icon={faBook}/>
                                        </div>
                                        <span>ChatDCP API</span>
                                    </button>
                                    <button onClick={() => setCurrentTab(3)} className="flex items-center px-4 py-2 text-gray-300 bg-gray-700 rounded hover:bg-gray-600 hover:text-white space-x-2">
                                        <div className="w-6 h-6 flex items-center justify-center">
                                            <FontAwesomeIcon icon={faBook}/>
                                        </div>
                                        <span>Token Viewer</span>
                                    </button>
                                </React.Fragment>
                            )}
                            {canViewPrompt && (
                                <React.Fragment>
                                <button onClick={() => setCurrentTab(4)} className="flex items-center px-4 py-2 text-gray-300 bg-gray-700 rounded hover:bg-gray-600 hover:text-white space-x-2">
                                    <div className="w-6 h-6 flex items-center justify-center">
                                        <FontAwesomeIcon icon={faBook}/>
                                    </div>
                                    <span>Prompt Management</span>
                                </button>
                            </React.Fragment>
                            )}
                        </div>
                    }
                />
                
                
            </div>
            <div className='flex flex-col flex-grow min-h-screen'>
                <MenuBar
                    isInDashboard={true}
                    pageLabel={getPageLabel()}
                />
                {renderContent()}
            </div>
        </div>
    );
};

export default Dashboard;