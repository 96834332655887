// Import necessary hooks and components from React and other custom components
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import ChatSidebar from './ChatSidebar'
import ConversationWindow from '../components/ConversationWindow'
import React from 'react'
import { useUser } from '../context/UserContext'
import { useAuth0 } from '@auth0/auth0-react'
import MenuBar from './MenuBar'
import SideBar from './Sidebar'
import ConversationDocuments from './ConversationDocuments'
import { useConversation } from '../context/ConversationContext'
import ConversationToolSelector from './ConversationToolSelect'



const Main: React.FC = () => {
    // State management for conversations, messages, and documents

    const {isLoading, isAuthenticated} = useAuth0()
    const { user} = useUser()

    // Hook to programmatically navigate using React router
    const navigate = useNavigate()

    // Effect hook to check auth0 state when component mounts
    useEffect(() => {
        if (!isLoading && !isAuthenticated){
            console.log("redirecting");
            navigate("/")
        }
    }, [user, navigate, isLoading, isAuthenticated])

    const { createNewConversation, selectedConversation, } = useConversation();
    const createNewConv = () => { createNewConversation(selectedConversation ? selectedConversation.tool : 0, null); }

    // sets bg color whenever component mounts
    useEffect(() => { document.body.classList.add("bg-slate-900"); return () => {document.body.classList.remove("bg-slate-900");} }, [])

    // Rendering the main component structure with Sidebar, ConversationWindow, and Modal
    return (
        <div className='flex w-screen'>
            <div>
                <SideBar
                    header = {
                        <div className='w-full bg-gray-800 p-4 border-b border-gray-700 flex justify-center'>
                            <button
                                className="px-4 py-2 rounded-md bg-blue-600 text-white text-md font-semibold transition duration-200 hover:bg-blue-500 hover:shadow-md"
                                onClick={createNewConv}
                            >
                                New Chat
                            </button>
                        </div>
                    }
                    body = {
                        <ChatSidebar/>
                    }
                    footer = {
                        <div className='h-12'>
                        </div>
                    }
                />
            </div>
            <div className='flex flex-col w-full min-h-screen '>
                <MenuBar
                    isInDashboard={false}
                    body={
                        <div className='flex'>
                            <ConversationToolSelector/>
                            <ConversationDocuments/>
                        </div>
                    }
                    // body={
                    //     <button
                    //         onClick={() => {}}
                    //         className="flex items-center px-4 py-2 bg-slate-800 text-white rounded-lg hover:bg-slate-700 focus:outline-none"
                    //     >
                    //         Select Document
                    //     </button>
                    // }
                />
                <ConversationWindow/>
            </div>
        </div>
    )
}

export default Main
