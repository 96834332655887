import React, { useCallback, useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp, faUpload } from '@fortawesome/free-solid-svg-icons'
import autosize from 'autosize'
import DocumentUpload from './AdminDashboard/DocumentManagement/UploadFiles'
import { useConversation } from '../context/ConversationContext'
import { useMessage } from '../context/MessageContext'


const ChatInput: React.FC = () => {

    // State to manage the input field content for the chat
    const [currentInput, setCurrentInput] = useState('')

    const { selectedConversation } = useConversation(); // Kinda hacky for now

    const { waitingForMessageResponse, handleSendMessage } = useMessage();

    const textareaRef = useRef<HTMLTextAreaElement>(null)
    
    React.useEffect(() => {
        // Focus the input when the conversationId changes
        if (textareaRef.current) {
            textareaRef.current.focus();
        }
    }, [selectedConversation])

    useEffect(() => {
        autosize(textareaRef.current as HTMLTextAreaElement)
    })

    const { reFetchFileList } = useConversation();



    const [isUploadOpen, setIsUploadOpen] = useState<boolean>(false);
    const closeUpload = () => {
        setIsUploadOpen(false);
        // requery the files
        reFetchFileList();
    }

    const handleUploadClick = useCallback(() => {
        setIsUploadOpen(true);
    }, []);

    return (
        <div
            // className={`mt-auto w-full bg-slate-900 sticky bottom-4 self-center pt-4 pb-${user?.admin ? '4' : '8'
            className={`mt-auto w-full bg-slate-900 sticky bottom-0 self-center py-2`}
        >
            <div className='flex'>
                {selectedConversation?.tool !== 0 && (<button
                    className={`${waitingForMessageResponse
                        ? 'bg-blue-700 cursor-default'
                        : 'bg-blue-600'
                        }  w-[5%] h-10 rounded-lg hover:bg-blue-700 focus:outline-none text-white self-center`}
                    onClick={ handleUploadClick }
                >
                    <FontAwesomeIcon
                        icon={faUpload}
                        className={`text-blue-100`}
                    />
                </button>)}

                <textarea
                    className='flex-grow px-4 py-2 rounded-lg focus:outline-none resize-none max-h-48 mx-4'
                    ref={textareaRef}
                    placeholder='Message ChatDCP...'
                    value={currentInput}
                    onChange={(e) => setCurrentInput(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                            e.preventDefault();
                            handleSendMessage(currentInput);
                            textareaRef.current?.setAttribute('style', '');
                            setCurrentInput('');
                        }
                    }}
                    rows={1}
                />

                <button
                    className={`${waitingForMessageResponse
                        ? 'bg-blue-700 cursor-default'
                        : 'bg-blue-600'
                        }  w-[5%] h-10 rounded-lg hover:bg-blue-700 focus:outline-none text-white self-center`}
                    onClick={() => {
                        if (!waitingForMessageResponse) {
                            textareaRef.current?.setAttribute('style', '')
                            handleSendMessage(currentInput)
                        }
                    }}
                >
                    <FontAwesomeIcon
                        icon={faArrowUp}
                        className={`text-blue-100`}
                    />
                </button>
            </div>

            {/* Upload Modal */}
            {isUploadOpen && 
                <DocumentUpload
                    dataTableSelection={-1}
                    dataTableName={"conversation"}
                    closeSelf={closeUpload}
                    conversation_id={selectedConversation?.id}
                />
            }
        </div>
    )
}

export default ChatInput
