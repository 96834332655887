import React from 'react'

interface UploadingFilesModalProps {
    modalState: number // 0 is closed, 1 is loading, 2 is complete, 3 is failure
    onClose: () => void
    loadingText: string
    successText?: string | null
    failureText?: string | null
}

const StatusModal: React.FC<UploadingFilesModalProps> = ({
    modalState,
    onClose,
    loadingText,
    successText,
    failureText
}) => {
    if (modalState === 0) return null

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-slate-200 p-6 rounded shadow-lg">
                <div className="flex flex-col items-center gap-6">
                {modalState === 1 && (
                    <div className="flex flex-col items-center">
                    <svg
                        className="w-12 h-12 text-blue-500 animate-spin"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                        ></circle>
                        <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8v4l3-3-3-3v4a8 8 0 00-8 8z"
                        ></path>
                    </svg>
                    <p className="text-gray-600 mt-2">{loadingText}</p>
                    </div>
                )}
                {modalState === 2 && (
                    <div className="flex flex-col items-center">
                        <svg
                        className="w-12 h-12 text-green-500"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="4"
                            d="M5 13l4 4L19 7"
                        />
                        </svg>
                        <p className="text-green-600 mt-2 font-semibold">{successText ? successText : "Success!"}</p>
                    </div>
                )}

                {modalState === 3 && (
                    <div className="flex flex-col items-center">
                        <svg
                        className="w-12 h-12 text-red-500"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="4"
                            d="M6 18L18 6M6 6l12 12"
                        />
                        </svg>
                        <p className="text-red-600 mt-2 font-semibold">{failureText ? failureText : "Operation Failed"}</p>
                    </div>
                )}
                <button
                    onClick={onClose}
                    className="bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-6 rounded"
                >
                    Close
                </button>
                </div>
            </div>
        </div>
    )
}

export default StatusModal
