import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { API_BASE_URL, AUTH0_DOMAIN } from '../constants'
import { useUser } from '../context/UserContext'
import { Auth0Context, useAuth0 } from '@auth0/auth0-react'

// Define the types for the props expected by the Login component
interface LoginProps {
}

// Login component accepting `setLoggedIn` and `setUserId` as props
const Login: React.FC<LoginProps> = () => {
    // State management for email and password inputs
    const [emailInput, setEmailInput] = useState('')
    const [password, setPassword] = useState('')
    const {loginUser} = useUser()
    // Hook to programmatically navigate using React router
    const navigate = useNavigate()
    const {isAuthenticated, loginWithRedirect, user, isLoading} = useAuth0();            // // API call to log in the user with provided email

    useEffect(() => {
        if (!isLoading && isAuthenticated) {
            navigate("/")
        }
      }, [isLoading, isAuthenticated]);
    
    // Function to handle the login button click
    const onButtonClick = async () => {
        // Guard clause to make sure both email and password are provided

        try {
            loginWithRedirect()
            console.log("LOGGING IN")
            console.log(`auth: ${isAuthenticated}`)
            console.log(`user: ${user}`)
            console.log(`loading: ${isLoading}`)
            // const loginResponse = await fetch(`${API_BASE_URL}/token`, {
            //     method: 'POST',
            //     headers: {
            //         'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            //     },
            //     body: formBody.join("&"),
            // })
            // if (!loginResponse.ok) {
            //     console.error('Login failed')
            //     return
            // }
            // const loginJson = await loginResponse.json()
            // const access_token = await getAccessTokenSilently({
            //     authorizationParams: {
            //       audience: AUTH0_DOMAIN, // Value in Identifier field for the API being called.
            //       scope: '', // Scope that exists for the API being called. You can create these through the Auth0 Management API or through the Auth0 Dashboard in the Permissions view of your API.
            //     }
            //   });


            // const userResponse = await fetch(`${API_BASE_URL}/user/me`, {
            //     method: 'GET',
            //     headers: {
            //         'Content-Type': 'application/json',
            //         'Authorization': `bearer ${access_token}`
            //     },
            // })
            // if (!userResponse.ok) {
            //     console.error('User fetch failed')
            //     return
            // }
            // const userJson = await userResponse.json()
            // loginUser({accessToken: access_token, id: userJson.id, email: userJson.email, admin: userJson.admin})
            // Set user ID and logged in state upon successful login

            // Navigate to the home page after login
            navigate('/')
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <div className='flex h-screen bg-slate-900 items-center justify-center'>
            {<div>{isAuthenticated}</div>}

            <div className='max-w-md w-full bg-white rounded-lg shadow-md p-6'>
                <div className='text-2xl mb-2 text-center font-semibold'>
                    ChatDCP
                </div>
                <div className='flex justify-center'>
                    <button
                        className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
                        type='button'
                        onClick={onButtonClick}
                    >
                        Sign in
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Login
