import React, { useEffect, useRef, useState } from 'react'
import { Message, DocumentsState } from '../types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faPen,
    faClipboard,
    faCheck,
    faChevronUp,
    faChevronDown,
} from '@fortawesome/free-solid-svg-icons'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { API_BASE_URL } from '../constants'
import { useUser } from '../context/UserContext'

interface MessageProps {
    message: Message
    editingMessageId: number | null
    waitingForResponse: boolean
    onSwitchEdit: (id: number, previous: boolean) => void
    onEditMessage: (id: number, text: string) => void
    editingInputRef: React.RefObject<HTMLTextAreaElement>
    setEditingName: React.Dispatch<React.SetStateAction<string>>
    editingName: string
    spanRef: React.RefObject<HTMLDivElement>
    setEditingMessageId: (messageId: number | null) => void
    requestSourceDisplay: (messageId: number, sources: { [sourceName: string]: string[] }) => void
}

const MessageComponent: React.FC<MessageProps> = ({
    message,
    editingMessageId,
    waitingForResponse,
    onSwitchEdit,
    onEditMessage,
    editingInputRef,
    setEditingName,
    editingName,
    spanRef,
    setEditingMessageId,
    requestSourceDisplay
}) => {
    const [copy, setCopy] = useState<boolean>(false)
    const [sourceExpanded, setSourceExpanded] = useState<boolean>(false)
    const messagesExpandRef = useRef<HTMLButtonElement>(null)
    const [displayTooltip, setDisplayTooltip] = useState<boolean>(false)
    const mouseInEdit = useRef<boolean>(false)

    const onMouseEnterEditButton = async () => {
        mouseInEdit.current = true;
        await new Promise(resolve => setTimeout(resolve, 200));
        if (mouseInEdit.current)
        {
            setDisplayTooltip(true);
        }
    }

    const onMouseExitEditButton = () => {
        mouseInEdit.current = false;
        setDisplayTooltip(false);
    }

    // Effect to handle auto-scrolling when sources are expanded
    useEffect(() => {
        if (sourceExpanded)
            setTimeout(() => {
                messagesExpandRef.current?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                })
            }, 100)
    }, [sourceExpanded])

    // Reset source expansion when the message changes
    useEffect(() => setSourceExpanded(false), [message.id])

    return (
        <div
            key={message.id}
            className={`flex flex-col justify-center max-w-screen-lg group ${
                message.role === 'user' ? 'items-end mt-6' : 'items-stretch mt-2'
            }`}
        >
            {
                // Show the edit navigation buttons if the message has edits and is not the currently edited message
                (message.nextEditID || message.previousEditID) &&
                    message.id !== editingMessageId &&
                    !waitingForResponse && (
                        <div className='flex justify-between w-16 self-end'>
                            <button
                                className={`text-s ${
                                    message.editIndex !== 0
                                        ? 'text-gray-400'
                                        : 'text-gray-700 cursor-default'
                                } hover:text-gray-700 transition`}
                                onClick={() => {
                                    if (message.editIndex !== 0)
                                        onSwitchEdit(message.id, true)
                                }}
                            >
                                {'<'}
                            </button>
                            <p className='text-s text-gray-400 '>
                                {message.editIndex +
                                    1 +
                                    '/' +
                                    message.editAmount}
                            </p>
                            <button
                                className={`text-s ${
                                    message.editIndex + 1 !== message.editAmount
                                        ? 'text-gray-400'
                                        : 'text-gray-700 cursor-default'
                                } hover:text-gray-700 transition`}
                                onClick={() => {
                                    if (
                                        message.editIndex + 1 !==
                                        message.editAmount
                                    )
                                        onSwitchEdit(message.id, false)
                                }}
                            >
                                {'>'}
                            </button>
                        </div>
                    )
            }
            <React.Fragment>
                {
                    // Show the message edit input if the message is being edited
                    message.id === editingMessageId ? (
                        <div
                            className={`rounded-lg px-4 py-2 max-w-lg break-words bg-blue-600 text-white flex flex-col space-y-2`}
                        >
                            <textarea
                                rows={1}
                                ref={editingInputRef}
                                value={editingName}
                                onChange={(e) => setEditingName(e.target.value)}
                                className={`max-w-full rounded text-gray-200 bg-blue-600 outline-none cursor-text resize-none overflow-hidden max-h-48`}
                            />
                            <div
                                ref={spanRef}
                                className='absolute left-[-9999px] px-4 min-h-[24px] max-w-lg text-wrap break-words whitespace-pre-line'
                                aria-hidden='true'
                            >
                                {editingName || ' '}
                            </div>
                        </div>
                    ) : (
                        // Show the message content if the message is not being edited
                        // Edit button
                        <div className={`${message.role === 'user' ? 'flex max-w-lg' : ''}`}>
                            { message.role === 'user' && (
                                <div className='relative h-full px-2 opacity-0 group-hover:opacity-100 transition-opacity duration-200'>
                                    {/* Tooltip */}
                                    {displayTooltip && (
                                        <span className={`absolute top-10 right-2 bg-gray-700 text-white text-xs px-2 py-1 rounded opacity-100 whitespace-nowrap`}>
                                            Edit Message
                                        </span>
                                    )}
                                    <div className='p-1' onMouseEnter={onMouseEnterEditButton} onMouseLeave={onMouseExitEditButton}>
                                        <FontAwesomeIcon
                                            icon={faPen}
                                            onClick={() => {
                                                if (!waitingForResponse) {
                                                    setEditingMessageId(
                                                        message.id
                                                    )
                                                    setEditingName(message.text)
                                                }
                                            }}
                                            className={`${
                                                !waitingForResponse
                                                    ? 'text-gray-400'
                                                    : 'text-gray-600 cursor-default'
                                            } hover:text-gray-600 transition-colors duration-200 cursor-pointer`}
                                        />
                                    </div>
                                </div>
                            )}

                            <div
                                className={`rounded-md px-4 py-2 break-words ${
                                    message.role === 'user'
                                        ? 'bg-blue-600 text-white'
                                        : 'text-white max-w-screen-lg'
                                }`}
                            >
                                {message.role === 'user' ? (
                                    <div className='whitespace-pre-line'>
                                        {message.text}
                                    </div>
                                ) : (
                                    // Render the message content using ReactMarkdown if it is an assistant message
                                    <div>
                                        {message.text ? (
                                            <ReactMarkdown
                                                remarkPlugins={[remarkGfm]}
                                            >
                                                {message.text}
                                            </ReactMarkdown>
                                        ) : (
                                            // Show a loading spinner if the message is waiting for a response
                                            <div className='bg-slate-900 h-10 rounded-r-lg focus:outline-none text-white text-center flex items-center justify-center'>
                                                <svg
                                                    aria-hidden='true'
                                                    className='h-4/5 w-4/5 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600'
                                                    viewBox='0 0 100 101'
                                                    fill='none'
                                                    xmlns='http://www.w3.org/2000/svg'
                                                >
                                                    <path
                                                        d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
                                                        fill='currentColor'
                                                    />
                                                    <path
                                                        d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
                                                        fill='currentFill'
                                                    />
                                                </svg>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                            {message.role !== 'user' && (
                                // Show the assistant name and source expansion button if the message is an assistant message
                                <div>
                                    {message.text && (
                                        <div className={`flex`}>
                                            {
                                                // Show the copy button if the message has text
                                            }
                                            <button
                                                className='ml-4 mt-2'
                                                onClick={() => {
                                                    navigator.clipboard.writeText(
                                                        message.text
                                                    )
                                                    setCopy(true)
                                                    setTimeout(() => {
                                                        setCopy(false)
                                                    }, 2000)
                                                }}
                                            >
                                                <FontAwesomeIcon
                                                    icon={
                                                        copy
                                                            ? faCheck
                                                            : faClipboard
                                                    }
                                                    className='text-gray-400 hover:text-gray-600'
                                                />
                                            </button>
                                            {/* Show the source expansion button if the message has sources */}
                                            {message.sources && Object.keys(message.sources).length > 0 && (
                                                <button
                                                    className="flex items-center bg-slate-600 rounded-full ml-2 p-2 py-1 mt-2 shadow hover:bg-slate-800"
                                                    onClick={() => {requestSourceDisplay(message.id, message.sources);}}
                                                >
                                                    <span className="font-medium text-sm text-gray-300">
                                                        Sources
                                                    </span>
                                                </button>
                                            )}
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    )
                }
            </React.Fragment>
            {message.id === editingMessageId && (
                // Show the save and cancel buttons if the message is being edited
                <div className='flex justify-center'>
                    <button
                        onClick={() => {
                            onEditMessage(message.id, editingName)
                        }}
                        onMouseDown={(e) => e.preventDefault()}
                        className='bg-green-600 font-bold text-gray-300 hover:bg-green-700 transition rounded py-1 px-2 mt-1 mx-1'
                    >
                        <div>Save</div>
                    </button>
                    <button
                        onMouseDown={(e) => e.preventDefault()}
                        onClick={() => {
                            setEditingMessageId(null)
                        }}
                        className='bg-slate-700 text-gray-300 font-bold hover:bg-gray-800 transition rounded py-1 px-2 mt-1 mx-1'
                    >
                        <div>Cancel</div>
                    </button>
                </div>
            )}
        </div>
    )
}

export default MessageComponent
