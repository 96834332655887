// Import necessary React components and hooks
import React from 'react'


// Define the props expected by the DashboardSidebar component

interface SideBarProps {
    header?: React.ReactNode | null,
    body?: React.ReactNode | null,
    footer?: React.ReactNode | null,
}

const SideBar: React.FC<SideBarProps> = ({
    header,
    body,
    footer,
}) => {
    return (
        <div className = 'h-full w-64 bg-gray-800'>
            <div className='sticky top-[0] h-screen flex flex-col'>
                {header && (
                    <React.Fragment>
                        {header}
                    </React.Fragment>
                )}
                {body && (
                    <div className='flex-grow overflow-y-auto overflow-x-hidden'>
                        {body}
                    </div>
                )}
                {footer && (
                    <React.Fragment>
                        {footer}
                    </React.Fragment>
                )}
            </div>
        </div>
    )
}

export default SideBar
