// Import necessary React components and types
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useUser } from '../context/UserContext'
import { DashboardPageLabel, LoginPageLabel } from '../constants'
import { jwtDecode } from 'jwt-decode'

// Define the props expected by the MenuBar component
interface MenuBarProps {
    isInDashboard: boolean
    pageLabel?: string | null
    body?: React.ReactNode | null,
}

const MenuBar: React.FC<MenuBarProps> = ({
    isInDashboard,
    pageLabel,
    body
}
    
) => {
    const [isMenuOpen, setMenuOpen] = useState(false)

    const [canNavigateToAdmin, setAdminDashboard] = useState(false)
    
    const {user} = useUser()

    const handleToggleMenu = () => {
        setMenuOpen(!isMenuOpen);
    }

    const { logoutUser } = useUser();
    const navigate = useNavigate();
    const handleLogoutUser = () => {
        logoutUser();
        navigate(`${LoginPageLabel}`)
    }

    const handleNavigateDashboard = () => {
        navigate(`${DashboardPageLabel}`)
    }

    const handleNavigateChatDCP = () => {
        navigate('/')
    }

    // Effect hook to check for admin perms when component mounts
    useEffect(() => {
        const token = user?.accessToken;
        if (token)
        {
            const decodedToken = jwtDecode(token) as Record<string, any>;
            const permissions = decodedToken['permissions'];

            if (permissions.includes('admindashboard'))
            {
                setAdminDashboard(true);
            }
            else
            {
                setAdminDashboard(false);
            }
        }
        else
        {
            setAdminDashboard(false); // invalid token means no access
        }
    }, [user?.accessToken])

    
    return (
        <div className="sticky flex top-0 z-50 space-x-2 p-4 pb-2 bg-slate-900 shadow-lg">
            {pageLabel && (
                <div className="text-white text-3xl">
                    {pageLabel}
                </div>
            )}
            {body}
            <div className="flex grow items-center justify-end">
                <button
                    className="flex items-center space-x-2 bg-gray-200 rounded-full px-4 py-2 shadow hover:bg-gray-300"
                    onClick={handleToggleMenu}
                >
                    <span className="font-medium">
                        {user?.email ? (
                            user.email
                        ) : (
                            <span className="inline-block bg-gray-300 h-4 w-32 rounded-md animate-pulse"></span>
                        )}
                    </span>
                </button>

                {/* Context Menu */}
                <div className={`absolute top-full mt-1 w-48 bg-white border rounded-lg shadow-lg transition-all duration-10 
                                ${isMenuOpen ? "opacity-100 visible" : "opacity-0 invisible"}`}
                >
                    <ul className="py-2">
                        {!isInDashboard && canNavigateToAdmin && (
                            <li onClick={handleNavigateDashboard} className="px-4 py-2 hover:bg-gray-100 cursor-pointer">Admin Dashboard</li>
                        )}
                        {isInDashboard && (
                            <li onClick={handleNavigateChatDCP} className="px-4 py-2 hover:bg-gray-100 cursor-pointer">Back to ChatDCP</li>
                        )}
                        <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">Profile</li>
                        <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">Settings</li>
                        <li onClick={handleLogoutUser} className="px-4 py-2 hover:bg-gray-100 cursor-pointer">Logout</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default MenuBar