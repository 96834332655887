import React, { ReactNode } from 'react'
import { BrowserRouter, useNavigate } from 'react-router-dom'
import { UserProvider } from './context/UserContext'
import { Auth0Provider } from '@auth0/auth0-react'
import { AUTH0_DOMAIN, AUTH0_CLIENTID, AUTH0_AUDIENCE, LoginPageLabel } from './constants'
import Container from './components/Container'
import { ConversationProvider } from './context/ConversationContext'
import { MessageProvider } from './context/MessageContext'

interface Auth0ProviderWithRedirectCallbackProps {
    children: ReactNode;
}

const Auth0ProviderWithRedirectCallback: React.FC<Auth0ProviderWithRedirectCallbackProps> = ({ children }) => {
    const navigate = useNavigate();
    const onRedirectCallback = (appState : any) => {
        navigate(
          appState && appState.returnTo ? appState.returnTo : window.location.pathname
        );
      };
      
    return (
        <Auth0Provider 
            onRedirectCallback={onRedirectCallback}
            domain={AUTH0_DOMAIN}
            clientId={AUTH0_CLIENTID}
            cacheLocation="localstorage" // This can help with refreshing issues
            authorizationParams={{
                redirect_uri: `${window.location.origin}/${LoginPageLabel}`,
                audience: AUTH0_AUDIENCE
            }}
            leeway={120}
        >
            {children}
        </Auth0Provider>
    );
};

function App() {


    return (
        <div>
            <BrowserRouter>
                <Auth0ProviderWithRedirectCallback>
                    <UserProvider>
                        <ConversationProvider>
                            <MessageProvider>
                                <Container />
                            </MessageProvider>
                        </ConversationProvider>
                    </UserProvider>
                </Auth0ProviderWithRedirectCallback>
            </BrowserRouter>

        </div>
    );
}

export default App
