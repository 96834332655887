import React, { useEffect, useState } from "react";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import { API_BASE_URL, BASE_URL } from "../constants";
import { useUser } from "../context/UserContext";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

const SwaggerDocs = () => {
    const { user } = useUser()

    const [spec, setSpec] = useState(null);

    const navigate = useNavigate();

    const err403 = React.useCallback(() => {
        navigate('/')
    }, [navigate])
    
    useEffect(() => { document.body.classList.add("bg-white"); return () => {document.body.classList.remove("bg-white");} }, [])
    
    // Check permissions on mount
    useEffect(() => {
        const token = user?.accessToken;
        if (token)
        {
            const decodedToken = jwtDecode(token) as Record<string, any>;
            const permissions = decodedToken['permissions'];
            if (permissions.includes('apidocumentation'))
            {
                const getJson = async() => {
                    const res = await fetch(`${API_BASE_URL}/docs`, {
                        headers: {
                            'Authorization': 'Bearer ' + user?.accessToken,
                        }
                    });
    
                    if (!res.ok) {
                        console.error('res not ok')
                        if (res.status === 403) {
                            err403();
                        }
                        return;
                    }
                    const data = await res.json();

                    data.servers = [
                        { url: BASE_URL, description: "Server" },
                    ];
                    
                    console.log("logging spec");
                    console.log(data);
                    setSpec(data);
                }
                getJson();
            }
            else
            {
                console.error('lacking permission!')
                err403();
                return;
            }
        }
    }, [err403, user?.accessToken])

    const onComplete = (swaggerUI: any) => {
        // if (user) {
        //     swaggerUI.preauthorizeBearer(user?.accessToken);
        // }
    }

    return (
        <React.Fragment>
            {spec ? 
                <SwaggerUI 
                    spec={spec}                    
                    onComplete={onComplete}
                    requestInterceptor={(req) => {
                        if (user?.accessToken) {
                            req.headers["Authorization"] = 'Bearer ' + user?.accessToken;
                        }
                        return req;
                    }}
                /> : 
                <p>Loading...</p>
            }
        </React.Fragment>
    );
};

export default SwaggerDocs;