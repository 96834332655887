// Import necessary React functions, components, and types
import React, { useRef, useEffect, useState, useCallback } from 'react'
import { SourceDocument } from '../types'
import autosize from 'autosize' // Utility for automatic textarea resizing
import MessageComponent from './Message'
import { useMessage } from '../context/MessageContext'
import { useConversation } from '../context/ConversationContext'
import DocumentsViewer from './DocumentsViewer'

// Define the props expected by the MessagesContainer component

const MessagesContainer: React.FC = () => {
    // Refs for handling UI effects in the message list and inputs
    const messagesEndRef = useRef<HTMLDivElement>(null) // For auto-scrolling to the latest message
    const editingInputRef = useRef<HTMLTextAreaElement>(null) // Ref to the textarea being edited
    const spanRef = useRef<HTMLDivElement>(null) // Ref to a span for measuring width for resizing
    const [editingName, setEditingName] = useState('') // State to hold the value of the editing input

    const { selectedConversation, viewConversationDocumentsDelegate, conversationDocuments } = useConversation();
    const { messagesList, handleEditMessage, editingMessageId, setEditingMessageId, waitingForMessageResponse, handleSwitchEditMessage } = useMessage();

    const convertToSourceDocuments = useCallback((): SourceDocument[] => {
        return Object.entries(conversationDocuments).map(([key, document]) => ({
            id: key,
            name: document[0],
            table: document[1]
        }));
    }, [conversationDocuments]);
    
    const [visibleDocuments, setVisibleDocuments] = useState<SourceDocument[]>([]);

    // Effect for auto-scrolling to the bottom of the messages list when messages update
    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
    }, [messagesList])

    // Effect to focus the input field when editing starts
    useEffect(() => {
        if (editingMessageId) {
            editingInputRef.current?.focus()
            // Set cursor position to the end of the text
            editingInputRef.current?.setSelectionRange(
                editingInputRef.current?.value.length,
                editingInputRef.current?.value.length
            )
        }
    }, [editingMessageId])

    // Effect to manage dynamic resizing of the input based on its content
    useEffect(() => {
        if (editingInputRef.current && spanRef.current) {
            const mirrorWidth = spanRef.current.offsetWidth // Measure the mirror span width
            if (mirrorWidth < 512 && editingName.indexOf('\n') === -1) {
                editingInputRef.current.style.width = `${mirrorWidth - 31}px` // Adjust width for single-line editing
            } else {
                editingInputRef.current.style.width = `${32}rem` // Default to a fixed width on multi-line or wider content
                autosize(editingInputRef.current) // Apply autosize to adjust height dynamically
            }
        }
    }, [editingName, editingMessageId])

    // Source display
    const [sources, setSources] = useState<{ [sourceName: string]: string[] }>({})
    const [currentMessageSource, setCurrentMessageSource] = useState<number>(-1)

    // Effect to bind delegate for displaying all conversation docs
    useEffect(() => {
        viewConversationDocumentsDelegate.current = () => {
            if (currentMessageSource === -2) {
                // setVisibleDocuments([]);
                setCurrentMessageSource(-1);
            }
            else {
                setVisibleDocuments(convertToSourceDocuments());
                setCurrentMessageSource(-2); // -2 is for conversation documents
            }
        }
    }, [convertToSourceDocuments, currentMessageSource, viewConversationDocumentsDelegate])
    

    const requestSourceDisplay = (messageId: number, sources: { [sourceName: string]: string[] }) => {
        if (currentMessageSource === messageId)
        {
            setCurrentMessageSource(-1); // Hide sources if we clicked the same message
        }
        else
        {
            setCurrentMessageSource(messageId);
            setVisibleDocuments([]); // clear the documents from the container
            setSources(sources);
        }
    }

    // Whenever the selectedConversation changes hide the message sources
    useEffect(() => {
        setCurrentMessageSource(-1);
    }, [selectedConversation])

    return (
        <div className="flex w-full h-full">
            {/* Main chat area (left) */}
            <div className='flex-1 flex flex-col pb-2 pr-2 mx-4'>
                {/* This empty div is used to push all chat messages upwards, ensuring the latest is visible */}
                <div className='flex-grow'></div>
                {/* Render each message using the MessageComponent */}
                {messagesList.map((message) => (
                    <MessageComponent
                        key={message.id} // Unique key for React list rendering
                        message={message}
                        editingInputRef={editingInputRef}
                        editingMessageId={editingMessageId}
                        onEditMessage={handleEditMessage}
                        onSwitchEdit={handleSwitchEditMessage}
                        waitingForResponse={waitingForMessageResponse}
                        setEditingName={setEditingName}
                        editingName={editingName}
                        spanRef={spanRef}
                        requestSourceDisplay={requestSourceDisplay} 
                        setEditingMessageId={ setEditingMessageId }             
                    />
                ))}
                {/* Ref div for scrolling to the bottom of the message list */}
                <div ref={messagesEndRef} />
            </div>

            {/* Slide-in right panel for sources */}
            <DocumentsViewer
                sources={sources}
                documents={visibleDocuments}
                hidden={currentMessageSource === -1}
                hideSelf={() => { setVisibleDocuments([]); setCurrentMessageSource(-1); }}
            />
        </div>
    )
}

export default MessagesContainer
