import { Conversation, ConversationContextType, DocumentsMap } from "../types";
import React, { createContext, ReactNode, useCallback, useContext, useEffect, useRef, useState } from "react";
import { useUser } from "./UserContext";
import { API_BASE_URL } from "../constants";


const ConversationContext = createContext<ConversationContextType | undefined>(undefined);

interface ConversationProviderProps {
    children: ReactNode
};

export const ConversationProvider: React.FC<ConversationProviderProps> = ({ children }) => {

    const {user} = useUser();

    const [conversationsList, setConversationsList] = useState<Conversation[]>([]);
    const [selectedConversation, setSelectedConversation] = useState<Conversation | null>(null);

    const [availableTools, setAvailableTools] = useState<string[]>(["NQDCP", "MARLA AI", "RFP", "Mezrah Universe"]);


    // ----------------------------------------------------------------------------------------------------------------------
    // Update conversation operations
    // ----------------------------------------------------------------------------------------------------------------------

    useEffect(() => {
        // fetch list of tools

        const fetchToolList = async () => {
            const toolResponse = await fetch(
                `${API_BASE_URL}/conversation/tool_list`,
                {
                    headers: {
                        'Authorization': `Bearer ${user?.accessToken}`,
                    },
                }
            );

            if (!toolResponse.ok) {
                console.error("Failed to fetch conversation tools!");
                return;
            }
            const toolJson = await toolResponse.json();
            setAvailableTools(toolJson);
        }
        fetchToolList();
    }, [user]);
    
    const updateConversation = (updatedConversationData: Conversation) => {
        setConversationsList((prevList) =>
            prevList.map((conv) =>
            conv.id === updatedConversationData.id ? updatedConversationData : conv
            )
        );
    };

    const setTopConversation = (conversation: Conversation) => {
        setConversationsList((prevConversations) => {
            // Remove the selected conversation from the list
            const filteredConversations = prevConversations.filter(
                (c) => c.id !== conversation.id
            );
    
            // Place the selected conversation at the top
            return [conversation, ...filteredConversations];
        });
    };

    // Callback to get conversations for current user from api
    const fetchConversations = useCallback(async (setNullAfterFetch: boolean) => {
        if (!user){
            return;
        }

        const conversationsResponse = await fetch(
            `${API_BASE_URL}/conversation/${user.auth0_id}`,
            {
                headers: {
                    'Authorization': `Bearer ${user.accessToken}`,
                },
            }
        );
        const conversationsDataJson = await conversationsResponse.json();

        const conversationsData: Conversation[] = conversationsDataJson.map(
            (conversation: any) => ({
                id: conversation.id,
                userId: conversation.user_id,
                name: conversation.name,
                createdAt: new Date(conversation.created_at),
                updatedAt: new Date(conversation.updated_at),
                lastViewedMessageId: conversation.last_viewed_message_id,
                tool: conversation.tool
            })
        );

        if (conversationsData.length < 1)
        {
            console.log("Found no conversations!");
            setSelectedConversation(null);
        }

        // Sorting conversations by update time
        conversationsData.sort(
            (a, b) => b.updatedAt.getTime() - a.updatedAt.getTime()
        )
        setConversationsList(conversationsData)
        if (setNullAfterFetch) // set null if requested
            setSelectedConversation(null)
    }, [user]);

    const defaultAgentMessage = "Hi! I am ChatDCP, your personal assistant for navigating Deferred Compensation Plans. How can I help you?";

    
    const createNewConversation = useCallback(async (selectedTool: number, agentMessage: string|null = defaultAgentMessage) => {

        if (user === null) return;
        if (!agentMessage) agentMessage = defaultAgentMessage;
        try {
            // API call to create a new conversation
            const conversationResponse = await fetch(
                `${API_BASE_URL}/conversation`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + user.accessToken
                    },
                    body: JSON.stringify({
                        tool: selectedTool,
                        agent_message: agentMessage
                    }),
                }
            )
            
            // Parse the response JSON and create a new conversation object
            const newConversationDataJson = await conversationResponse.json()
            const newConversationData: Conversation = {
                id: newConversationDataJson.conversation.id,
                userId: newConversationDataJson.conversation.user_id,
                name: newConversationDataJson.conversation.name,
                createdAt: new Date(
                    newConversationDataJson.conversation.created_at
                ),
                updatedAt: new Date(
                    newConversationDataJson.conversation.updated_at
                ),
                lastViewedMessageId:
                    newConversationDataJson.conversation.last_viewed_message_id,
                hasUserMessage: false,
                isWaitingForName: false,
                tool: newConversationDataJson.conversation.tool,
            }

            // Add the new conversation to the conversations array
            newConversationData.lastViewedMessageId = newConversationDataJson.message.id
            console.log("setting conversations in oncreate new conversation useconversations")
            fetchConversations(false); // We don't want to change the selected conversation here
            setSelectedConversation(newConversationData)
        } catch (error) {
            console.error('Error creating new conversation:', error)
        }
    }, [fetchConversations, user]);
    
    // Rename a conversation
    const renameConversation = useCallback(async (conversationId: number, newName: string) => {
        if (user === null) return;

        // Guard clause to prevent renaming a conversation to an empty string
        if (newName.trim() === '') newName = 'New Chat';

        try {

            // API call to rename the conversation
            const conversationResponse = await fetch(
                `${API_BASE_URL}/conversation/rename`,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + user.accessToken,
                    },
                    body: JSON.stringify({
                        name: newName,
                        id: conversationId,
                    }),
                }
            );

            // Parse the response JSON and update the conversation object
            const updatedConversationDataJson = await conversationResponse.json();

            const updatedConversationData: Conversation = {
                id: updatedConversationDataJson.id,
                userId: updatedConversationDataJson.user_id,
                name: updatedConversationDataJson.name,
                createdAt: new Date(updatedConversationDataJson.created_at),
                updatedAt: new Date(updatedConversationDataJson.updated_at),
                lastViewedMessageId:
                    updatedConversationDataJson.last_viewed_message_id,
                hasUserMessage: false,
                isWaitingForName: false,
                tool: updatedConversationDataJson.tool
            };
            
            // Update the conversation in the conversations list
            updateConversation(updatedConversationData);

            if (updatedConversationData.id === selectedConversation?.id)
            {
                setSelectedConversation(updatedConversationData);
            }
            
            
        } catch (error) {
            console.error('Error renaming conversation:', error)
        }
    }, [selectedConversation, user]);

    
    useEffect(() => {
        if (user && !selectedConversation)
        {
            createNewConversation(0, null);
        }
    }, [createNewConversation, selectedConversation, user])
    
    
    const deleteConversation = useCallback(async (conversationId: number) => {

        if (!user) { return; }

        setConversationsList((prevList) => prevList.filter((conv) => conv.id !== conversationId));
        console.log("setting conversations in delete conversation useConversations");

        if (conversationId === selectedConversation?.id) {
            setSelectedConversation(conversationsList[0]);
        }
        try {
            fetch(
                `${API_BASE_URL}/conversation/delete/${conversationId}`,
                {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + user.accessToken,
                    },
                }
            )
        } catch (error) {
            console.error('Error deleting conversation:', error)
        }
    }, [conversationsList, selectedConversation?.id, user]);

    // ----------------------------------------------------------------------------------------------------------------------
    // Conversation document handling
    // ----------------------------------------------------------------------------------------------------------------------
    const [conversationDocuments, setConversationDocuments] = useState<DocumentsMap>([]);
    const [loadingConversationDocuments, setLoadingConversationDocuments] = useState<boolean>(false);
    const viewConversationDocumentsDelegate = useRef<() => void>(() => {});

    const fetchFileList = React.useCallback(() => {
        const fileApiCall = async () => {
            if (selectedConversation?.id === undefined)
            {
                console.log("undefined conversation");
                return;
            }
            setLoadingConversationDocuments(true);
            const fileResponse = await fetch(
                `${API_BASE_URL}/conversation/documents/${selectedConversation?.id}`,
                {
                    headers: {
                        'Authorization': `Bearer ${user?.accessToken}`,
                    },
                }
            );
            const fileDataJson = await fileResponse.json();

            if (!fileResponse.ok) {
                setConversationDocuments([]);
                setLoadingConversationDocuments(false);
                return;
            }

            setConversationDocuments(fileDataJson);
            setLoadingConversationDocuments(false);
            
        };

        fileApiCall();
        
    }, [selectedConversation?.id, user?.accessToken]);

    // fetch list of documents whenever selected table or user changes
    useEffect(() => {
        fetchFileList();
    }, [fetchFileList]);

    // ----------------------------------------------------------------------------------------------------------------------
    // ----------------------------------------------------------------------------------------------------------------------


    // This effect queries all conversations when the user changes
    useEffect(() => {
        fetchConversations(true); // if the user has changed we should 'reset' by setting the first convo
    }, [fetchConversations]);
    

    const contextValue: ConversationContextType = { 
        conversationsList,
        setTopConversation,
        updateConversation,
        reFetchConversationsList: () => { fetchConversations(false); },
        selectedConversation,
        setSelectedConversation,
        createNewConversation,
        renameConversation,
        deleteConversation,
        availableTools,
        conversationDocuments,
        loadingConversationDocuments,
        reFetchFileList: fetchFileList,
        viewConversationDocumentsDelegate,
    };

    return (
        <ConversationContext.Provider value={contextValue}>
            {children}
        </ConversationContext.Provider>
    );
};

export const useConversation = (): ConversationContextType => {
    const context = useContext(ConversationContext);
    if (context === undefined) {
        throw new Error('useConversation must be used within a ConversationProvider');
    }
    return context;
}