import React from 'react';

import { useConversation } from '../context/ConversationContext';

const ConversationDocuments: React.FC  = () => {

    const { conversationDocuments, viewConversationDocumentsDelegate } = useConversation();

    // const [isOpen, setIsOpen] = useState(false);


    return (
        Object.keys(conversationDocuments).length > 0 ? (
            <React.Fragment>
                <div className="relative inline-block text-left">
                <button
                    // onClick={() => setIsOpen(!isOpen)}
                    onClick={() => viewConversationDocumentsDelegate.current()}
                    className="flex items-center px-4 py-2 bg-slate-800 text-white rounded-lg hover:bg-slate-700 focus:outline-none"
                >
                    Documents
                    {/* <span className="ml-2">{isOpen ? '▲' : '▼'}</span> */}
                </button>

                {/* {isOpen && (
                    <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-lg shadow-lg z-50">
                    <ul className="py-2">
                        {Object.entries(conversationDocuments).map(([key, document]) => {
                            const [name, type] = document; // Destructure tuple
                            
                            return (
                                <li
                                    key={key}
                                    onClick={() => {
                                    // onSelect(doc);
                                    // setIsOpen(false);
                                    }}
                                    className="px-4 py-2 cursor-pointer hover:bg-slate-100"
                                >
                                    {name}.{type}
                                </li>
                            )
                        
                        })}
                    </ul>
                    </div>
                )} */}
                </div>
            </React.Fragment>
        ) : null
    );
};
    
export default ConversationDocuments;