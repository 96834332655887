import React from "react";

const UserManagement = () => {

    return (
        <div className="flex-grow flex items-center justify-center text-white">
            <h1 className="text-2xl font-bold">🚧 Under Development 🚧</h1>
            <p className="mt-2 text-lg">This feature is not available yet. Check back soon!</p>
        </div>
    );
};

export default UserManagement;