import React, { createContext, ReactNode, useCallback, useContext, useEffect, useRef, useState } from "react";
import { Conversation, Message, MessageContextType } from "../types";
import { useUser } from "./UserContext";
import { useConversation } from "./ConversationContext";
import { API_BASE_URL, DEFAULT_SYSTEM_PROMPT } from "../constants";
import { SSE } from "sse.js";


const MessageContext = createContext<MessageContextType | undefined>(undefined);

interface MessageProviderProps {
    children: ReactNode
};

export const MessageProvider: React.FC<MessageProviderProps> = ({ children }) => {

    const { user } = useUser();
    const { selectedConversation, updateConversation, setTopConversation, setSelectedConversation } = useConversation();

    const [editingMessageId, setEditingMessageId] = useState<number | null>(null);

    const [messagesList, setMessagesList] = useState<Message[]>([]);
    const [waitingForResponse, setWaitingForResponse] = useState<boolean>(false);

    const currentConversationId = useRef<number>(-1);

    const fetchConversationMessages = useCallback(async (conversation: Conversation) => {
        try {
            setMessagesList([]);
            if (!user || !conversation) {
                return
            }
            console.log('Fetching messages')
            const messagesResponse = await fetch(
                `${API_BASE_URL}/message/conversation_${conversation!.id}`,
                {
                    headers: {
                        'Authorization': `Bearer ${user.accessToken}`,
                    },
                }
            )
            const messagesDataJson = await messagesResponse.json()
            const messagesData: Message[] = messagesDataJson.map(
                (message: any) => ({
                    id: message.id,
                    userID: message.user_id,
                    text: message.text,
                    role: message.role,
                    conversationID: message.conversation_id,
                    createdAt: new Date(message.created_at),
                    nextMessageID: message.next_message_id,
                    previousMessageID: message.previous_message_id,
                    editIndex: message.edit_index,
                    previousEditID: message.previous_edit_id,
                    nextEditID: message.next_edit_id,
                    editAmount: message.edit_amount,
                    sources: message.sources,
                    systemPromptID: message.system_prompt_id,
                })
            )
            // Creating a map of messages by id for easy access
            let messageMap: { [key: string]: Message } = {}
            messagesData.forEach((message: Message) => {
                messageMap[message.id] = message
            })
            // Collecting messages starting from the last viewed message
            let currMessages: Message[] = []
            let currMessage: Message | null =
                messageMap[conversation.lastViewedMessageId]
            currMessages.push(currMessage)
            while (currMessage?.previousMessageID) {
                currMessage = messageMap[currMessage.previousMessageID]
                currMessages.push(currMessage)
            }
            setMessagesList(currMessages.reverse())
        } catch (error) {
            console.error('Error fetching messages: ', error)
        }
    }, [user])

    // effect to fetch new convs whenever the selected conv changes
    useEffect(() => {
        if (!selectedConversation)
        {
            currentConversationId.current = -1;
            setMessagesList([]); // clear messages
        }
        else if (selectedConversation.id !== currentConversationId.current)
        {
            currentConversationId.current = selectedConversation.id;
            fetchConversationMessages(selectedConversation);
        }
    }, [fetchConversationMessages, selectedConversation])



    /**
     * Sends a new message in the currently selected conversation.
     * This function first checks if there's a selected conversation and if the system is already waiting for a response.
     * If not, it proceeds to simulate sending a message by updating the state with a new user message followed by a placeholder for the bot's response.
     * Actual sending to the backend and error handling are simulated.
     *
     * @param {string} newMessageText The text content of the new message.
     */
    const handleSendMessage = async (newMessageText: string) => {

        if (!user) { throw new Error('User not found') }

        // Prevent message sending if no conversation is selected or if already waiting for another response
        if (!selectedConversation || waitingForResponse) return

        const originalMessages: Message[] = messagesList;

        try {
            // Indicate that a response is being awaited
            setWaitingForResponse(true)

            // Create a new message object for the user
            let newMessages: Message[] = [ ...messagesList ]
            newMessages.push({
                id: -1, // Temporary ID until confirmed by the server
                userID: user.auth0_id,
                text: newMessageText,
                role: 'user',
                conversationID: selectedConversation.id,
                createdAt: new Date(),
                nextMessageID: null,
                previousMessageID: null,
                editIndex: 0,
                previousEditID: null,
                nextEditID: null,
                editAmount: 1,
                sources: {},
            })

            // Create a placeholder bot message
            let botMessageData = {
                id: -2, // Temporary ID until confirmed by the server
                userID: user.auth0_id,
                text: '',
                role: 'assistant',
                conversationID: selectedConversation.id,
                createdAt: new Date(),
                nextMessageID: null,
                previousMessageID: null,
                editIndex: 0,
                previousEditID: null,
                nextEditID: null,
                editAmount: 1,
                sources: {},
            }

            // Add the bot message to the conversation
            newMessages.push(botMessageData)
            setMessagesList(newMessages)

            // Simulate sending the message and getting a response from the server
            await sendMessage(newMessages, newMessageText);
            
        } catch (error) {
            // Log and handle errors by resetting to original states
            console.error('Error sending message:', error)
            setMessagesList(originalMessages)
            setWaitingForResponse(false)
        }
    }

    const sendMessage = useCallback(async (messageList: Message[], newMessageText: string) => {
    
        const systemPrompt = { id: -1, prompt: DEFAULT_SYSTEM_PROMPT };

        // update conversations instantly
        setTopConversation(selectedConversation!);
    
        const noBotMessageList = messageList.filter((msg) => msg.id !== -2);
        const requestBody = {
            messages: noBotMessageList.map((msg) => ({
                role: msg.role,
                content: msg.text,
            })),
            text: newMessageText,
            conversation_id: selectedConversation!.id,
            user_id: user!.auth0_id,
            previous_edit_id: null,
            system_prompt: systemPrompt["prompt"],
            system_prompt_id: systemPrompt["id"],
        }
    
        // Create a new EventSource object to stream the response from the server
        const eventSource = new SSE(`${API_BASE_URL}/chat`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user!.accessToken
            },
            payload: JSON.stringify(requestBody),
        })

        let sources: any = null
        let messageText = '' // The text of the message received from the server

        // Create a placeholder bot message
        let botMessageData = {
            id: -2, // Temporary ID until confirmed by the server
            userID: user!.auth0_id,
            text: '',
            role: 'assistant',
            conversationID: selectedConversation!.id,
            createdAt: new Date(),
            nextMessageID: null,
            previousMessageID: null,
            editIndex: 0,
            previousEditID: null,
            nextEditID: null,
            editAmount: 1,
            sources: {},
        };
        
        // Event listener for the message event
        eventSource.onmessage = function (event) {
            try {
                let data = JSON.parse(event.data)
                // Check if the data contains sources
                if ('sources' in data) {
                    sources = data['sources']
                }
                // Check if the data contains text
                else if ('text' in data) {
                    messageText += data['text']
                    botMessageData.text = messageText;
                    setMessagesList((prevList) => [
                        ...prevList.filter((msg) => msg.id !== botMessageData.id), // Remove any existing message with the same ID
                        botMessageData, // Add the new bot message
                    ]);
                }
                // Check if the data contains user and chatbot message data
                else if (
                    'userMessageData' in data &&
                    'chatbotMessageData' in data
                ) {
                    // Create message objects from the data
                    let userMessageData: Message = {
                        id: data['userMessageData'].id,
                        userID: data['userMessageData'].user_id,
                        createdAt: new Date(data['userMessageData'].created_at),
                        text: data['userMessageData'].text,
                        role: data['userMessageData'].role,
                        editIndex: data['userMessageData'].edit_index,
                        editAmount: data['userMessageData'].edit_amount,
                        conversationID: data['userMessageData'].conversation_id,
                        previousMessageID:
                            data['userMessageData'].previous_message_id,
                        nextMessageID: data['userMessageData'].next_message_id,
                        previousEditID: data['userMessageData'].previous_edit_id,
                        nextEditID: data['userMessageData'].next_edit_id,
                        sources: data['userMessageData'].sources,
                    }
                    botMessageData = {
                        id: data['chatbotMessageData'].id,
                        userID: data['chatbotMessageData'].user_id,
                        createdAt: new Date(data['chatbotMessageData'].created_at),
                        text: data['chatbotMessageData'].text,
                        role: data['chatbotMessageData'].role,
                        editIndex: data['chatbotMessageData'].edit_index,
                        editAmount: data['chatbotMessageData'].edit_amount,
                        conversationID: data['chatbotMessageData'].conversation_id,
                        previousMessageID:
                            data['chatbotMessageData'].previous_message_id,
                        nextMessageID: data['chatbotMessageData'].next_message_id,
                        previousEditID: data['chatbotMessageData'].previous_edit_id,
                        nextEditID: data['chatbotMessageData'].next_edit_id,
                        sources: data['chatbotMessageData'].sources,
                    }
                    eventSource.close()
                    const newMessages = [
                        ...messagesList.filter(
                            (msg) => msg.id !== -2 && msg.id !== -1
                        ),
                        userMessageData,
                        botMessageData,
                    ]
                    setMessagesList(newMessages);
                    console.log("setting conversations in send message listener chatAPI")
                    setWaitingForResponse(false)
    
                    async function GenerateConversationName() {
                        console.log("generating conversation name!");
                        try
                        {
                            // Add spinner after conversation name
                            const newConv = selectedConversation!;
                            newConv.isWaitingForName = true;
                            setTopConversation(newConv);
    
                            // API call to rename the conversation
                            const conversationResponse = await fetch(
                                `${API_BASE_URL}/conversation/generate_name`,
                                {
                                    method: 'PUT',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'Authorization': 'Bearer ' + user!.accessToken,
                                    },
                                    body: JSON.stringify({
                                        user_message: newMessageText,
                                        id: selectedConversation?.id,
                                    }),
                                }
                            )
                
                            // Parse the response JSON and update the conversation object
                            const updatedConversationDataJson =
                                await conversationResponse.json()
                            const updatedConversationData: Conversation = {
                                id: updatedConversationDataJson.id,
                                userId: updatedConversationDataJson.user_id,
                                name: updatedConversationDataJson.name,
                                createdAt: new Date(updatedConversationDataJson.created_at),
                                updatedAt: new Date(updatedConversationDataJson.updated_at),
                                lastViewedMessageId:
                                    updatedConversationDataJson.last_viewed_message_id,
                                hasUserMessage: true,
                                isWaitingForName: false,
                                tool: updatedConversationDataJson.tool
                            }
                            setTopConversation(updatedConversationData);
                            console.log("setting conversations in generate name chatAPI")
                            if (selectedConversation?.id === updatedConversationData.id) {
                                setSelectedConversation(updatedConversationData)
                            }                
                        }
                        catch (error) {
                            console.log("Error generating conversation name! " + error);
                        }
                    }
    
                    GenerateConversationName();
                    
                }
            } catch (e) {
                console.error(e)
                setWaitingForResponse(false)
            }
        }
    
        eventSource.onerror = function (error) {
            console.error('EventSource failed:', error)
            setWaitingForResponse(false)
            eventSource.close()
            throw new Error('EventSource failed')
        }
    
    }, [messagesList, selectedConversation, setSelectedConversation, setTopConversation, user]);


    /**
     * Edits an existing message by appending a new version of the text, effectively creating a new edit
     * entry for the message. This is done by fetching the latest state of the conversation's messages,
     * updating the local state, and attempting to post the new message version to the server.
     *
     * @param {number} messageId The ID of the message to edit.
     * @param {string} newMessageText The updated text for the message.
     */
    const handleEditMessage = async (messageId: number, newMessageText: string) => {
        
        if (!user) { throw new Error('User not found') }
        // Guard clause to ensure there is a selected conversation and new message text
        if (!selectedConversation || !newMessageText.trim()) return

        try {
            // Indicate that the app is waiting for a response
            setWaitingForResponse(true)

            // Find the index of the message being edited
            const editedMessageIndex = messagesList.findIndex(
                (msg) => msg.id === messageId
            )

            // Slice messages up to the edited one (non-inclusive)
            let newMessages = messagesList.slice(0, editedMessageIndex)

            // Fetch all conversation messages from the server to ensure sync with the latest state
            const allConversationMessagesJson = await fetch(
                `${API_BASE_URL}/message/conversation_${selectedConversation.id}`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + user.accessToken,
                    }
                }
            ).then((response) => response.json())

            // Construct a map of messages by their IDs
            let messageMap: { [key: string]: Message } = {}
            allConversationMessagesJson.forEach((m: any) => {
                let message: Message = {
                    id: m.id,
                    userID: m.userID,
                    text: m.text,
                    role: m.role,
                    conversationID: m.conversation_id,
                    createdAt: new Date(m.created_at),
                    nextMessageID: m.next_message_id,
                    previousMessageID: m.previous_message_id,
                    editIndex: m.edit_index,
                    previousEditID: m.previous_edit_id,
                    nextEditID: m.next_edit_id,
                    editAmount: m.edit_amount,
                    sources: m.sources,
                }
                messageMap[message.id] = message
            })

            // Determine the last message in the edit chain
            let lastMessageEdit: Message = messageMap[messageId]
            while (lastMessageEdit.nextEditID) {
                lastMessageEdit = messageMap[lastMessageEdit.nextEditID]
            }

            // Calculate the new edit index
            const newEditAmount = lastMessageEdit.editAmount + 1

            // Append the new user message
            newMessages.push({
                id: -1, // Temporary ID
                userID: user.auth0_id,
                text: newMessageText,
                role: 'user',
                conversationID: selectedConversation.id,
                createdAt: new Date(),
                nextMessageID: null,
                previousMessageID: lastMessageEdit.previousMessageID,
                editIndex: newEditAmount - 1,
                previousEditID: lastMessageEdit.id,
                nextEditID: null,
                editAmount: newEditAmount,
                sources: {},
            })

            // Create a placeholder for bot response
            let botMessageData = {
                id: -2, // Temporary ID
                userID: user.auth0_id,
                text: '',
                role: 'assistant',
                conversationID: selectedConversation.id,
                createdAt: new Date(),
                nextMessageID: null,
                previousMessageID: newMessages[newMessages.length - 1].id,
                editIndex: 0,
                previousEditID: null,
                nextEditID: null,
                editAmount: 1,
                sources: {},
                systemPromptID: null,
            }

            // Append the bot message
            newMessages.push(botMessageData)
            setMessagesList(newMessages);

            // Attempt to update the message on the server
            await editMessage(
                newMessageText,
                botMessageData,
                lastMessageEdit.id,
                newMessages
            )
        } catch (error) {
            // Handle errors by logging and reverting to original state
            console.error('Error editing message:', error)
            setWaitingForResponse(false)
        }
    }

    const editMessage = async (
        newMessageText: string,
        botMessageData: Message,
        previousEditId: number,
        newMessages: Message[]
    ) => {
        const systemPrompt = { id: -1, prompt: DEFAULT_SYSTEM_PROMPT };

        const requestBody = {
            messages: newMessages.filter((msg) => msg.id !== -2).map((msg) => ({
                role: msg.role,
                content: msg.text,
            })),
            text: newMessageText,
            conversation_id: messagesList[0].conversationID,
            user_id: messagesList[0].userID,
            previous_edit_id: previousEditId,
            system_prompt: systemPrompt["prompt"],
            system_prompt_id: systemPrompt["id"],
        };
        const eventSource = new SSE(`${API_BASE_URL}/chat/edit`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user!.accessToken
            },
            payload: JSON.stringify(requestBody),
        });
        let sources: any = null;
        let messageText = '';
        eventSource.onmessage = function (event) {
            try {
                let data = JSON.parse(event.data);
                if ('sources' in data) {
                    sources = data['sources']
                } else if ('text' in data) {
                    messageText += data['text'];
                    botMessageData.text = messageText;
                    const updatedMessages = [
                        ...newMessages.filter((msg) => msg.id !== botMessageData.id), // Remove any existing message with the same ID
                        botMessageData, // Add the new bot message
                    ]
                    setMessagesList(updatedMessages);
                } else if (
                    'userMessageData' in data &&
                    'chatbotMessageData' in data
                ) {
                    let userMessageData: Message = {
                        id: data['userMessageData'].id,
                        userID: data['userMessageData'].user_id,
                        createdAt: new Date(data['userMessageData'].created_at),
                        text: data['userMessageData'].text,
                        role: data['userMessageData'].role,
                        editIndex: data['userMessageData'].edit_index,
                        editAmount: data['userMessageData'].edit_amount,
                        conversationID: data['userMessageData'].conversation_id,
                        previousMessageID:
                            data['userMessageData'].previous_message_id,
                        nextMessageID: data['userMessageData'].next_message_id,
                        previousEditID: data['userMessageData'].previous_edit_id,
                        nextEditID: data['userMessageData'].next_edit_id,
                        sources: data['userMessageData'].sources,
                    };
                    botMessageData = {
                        id: data['chatbotMessageData'].id,
                        userID: data['chatbotMessageData'].user_id,
                        createdAt: new Date(data['chatbotMessageData'].created_at),
                        text: data['chatbotMessageData'].text,
                        role: data['chatbotMessageData'].role,
                        editIndex: data['chatbotMessageData'].edit_index,
                        editAmount: data['chatbotMessageData'].edit_amount,
                        conversationID: data['chatbotMessageData'].conversation_id,
                        previousMessageID:
                            data['chatbotMessageData'].previous_message_id,
                        nextMessageID: data['chatbotMessageData'].next_message_id,
                        previousEditID: data['chatbotMessageData'].previous_edit_id,
                        nextEditID: data['chatbotMessageData'].next_edit_id,
                        sources: data['chatbotMessageData'].sources,
                    };
    
                    eventSource.close();
                    const newerMessages = [
                        ...newMessages.filter(
                            (msg) => msg.id !== -2 && msg.id !== -1
                        ),
                        userMessageData,
                        botMessageData,
                    ]
                    setMessagesList(newerMessages);
                    console.log("setting conversations in send message listener chatAPI")
                    setWaitingForResponse(false)
                    
                    setEditingMessageId(null)
                }
            } catch (e) {
                console.error(e)
                setWaitingForResponse(false)
            }
        }
    
        eventSource.onerror = function (error) {
            console.error('EventSource failed:', error)
            setWaitingForResponse(false)
            eventSource.close()
            throw new Error('EventSource failed')
        }
    }

    /**
     * Switches between different edit versions of a message within a conversation.
     *
     * @param {number} messageId The ID of the current message being viewed.
     * @param {boolean} back A flag to determine the direction to switch: `true` for previous, `false` for next.
     */
    const onSwitchEdit = async (messageId: number, back: boolean) => {
        if (!user) { throw new Error('User not found') }
        // Ensure there is a selected conversation
        if (selectedConversation === null) return

        // Find the index of the message currently being edited
        const editedMessageIndex = messagesList.findIndex(
            (msg) => msg.id === messageId
        )

        // Slice messages up to the edited one (non-inclusive)
        let newMessages = messagesList.slice(0, editedMessageIndex)

        // Fetch all conversation messages from the server to ensure sync with the latest state
        const allConversationMessagesJson = await fetch(
            `${API_BASE_URL}/message/conversation_${selectedConversation.id}`,
            {
                headers: {
                    'Authorization': 'Bearer ' + user.accessToken,
                }
            }
        ).then((response) => response.json())

        // Construct a map of messages by their IDs
        let messageMap: { [key: string]: Message } = {}
        allConversationMessagesJson.forEach((m: any) => {
            let message: Message = {
                id: m.id,
                userID: m.userID,
                text: m.text,
                role: m.role,
                conversationID: m.conversation_id,
                createdAt: new Date(m.created_at),
                nextMessageID: m.next_message_id,
                previousMessageID: m.previous_message_id,
                editIndex: m.edit_index,
                previousEditID: m.previous_edit_id,
                nextEditID: m.next_edit_id,
                editAmount: m.edit_amount,
                sources: m.sources,
            }
            messageMap[message.id] = message
        });

        // Determine the last message in the edit chain
        let currMessage: Message = messageMap[messageId];

        // Add the current message to the list of messages to display
        if (back && currMessage.previousEditID) {
            currMessage = messageMap[currMessage.previousEditID] // Move to the previous edit
            newMessages.push(currMessage)
        } else if (!back && currMessage.nextEditID) {
            currMessage = messageMap[currMessage.nextEditID] // Move to the next edit
            newMessages.push(currMessage)
        }

        // Update messages to include all following the current message
        while (currMessage.nextMessageID) {
            currMessage = messageMap[currMessage.nextMessageID]
            newMessages.push(currMessage)
        }

        // Update the messages state with the new list
        setMessagesList(newMessages);

        // Update the last viewed message ID in the selected conversation
        selectedConversation.lastViewedMessageId = currMessage.id
        setSelectedConversation({ ...selectedConversation })

        // Update the conversations array with the new state of the selected conversation
        updateConversation(selectedConversation);
        console.log("setting conversations in onswitchedit useMessages")


        try {
            // Attempt to update the last viewed message ID on the server
            await fetch(`${API_BASE_URL}/conversation/last_message`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.accessToken,
                },
                body: JSON.stringify({
                    last_viewed_message_id: currMessage.id,
                    id: selectedConversation.id,
                }),
            })
        } catch (error) {
            // Handle errors by reverting to the original state and logging the error
            console.error('Error updating conversation:', error);
        }
    }
    
    const contextValue: MessageContextType = { 
        messagesList,
        handleSendMessage,
        handleEditMessage,
        handleSwitchEditMessage: onSwitchEdit,
        waitingForMessageResponse: waitingForResponse,
        editingMessageId,
        setEditingMessageId
    };

    return (
        <MessageContext.Provider value={contextValue}>
            {children}
        </MessageContext.Provider>
    )
};

export const useMessage = (): MessageContextType => {
    const context = useContext(MessageContext);
    if (context === undefined) {
        throw new Error('useMessage must be used within a MessageProvider');
    }
    return context;
}