import React, { useEffect, useState } from 'react';
import { API_BASE_URL } from '../../../constants';

import { useUser } from '../../../context/UserContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import DocumentUpload from './UploadFiles';
import StatusModal from '../../StatusModal';


const DocumentManagement = () => {

    const { user } = useUser()

    const [selectedTable, setSelectedTable] = useState<number>(0)
    const [availableTables, setAvailableTables] = useState<[string]>([""]);
    
    const [files, setFiles] = useState([])
    const [filesLoading, setFilesLoading] = useState<boolean>(false)

    // fetch list of available tables
    useEffect(() => {
        // fetch list of tools

        const fetchToolList = async () => {
            const fileResponse = await fetch(
                `${API_BASE_URL}/dashboard/tables`,
                {
                    headers: {
                        'Authorization': `Bearer ${user?.accessToken}`,
                    },
                }
            );

            if (!fileResponse.ok) {
                throw new Error("failed to fetch tables");
            }

            const fileDataJson = await fileResponse.json();
            setAvailableTables(fileDataJson);
        }
        fetchToolList();
    }, [user]);

    const fetchFileList = React.useCallback(() => {
        const fileApiCall = async () => {
            if (selectedTable >= 0) {
                setFilesLoading(true);
                const fileResponse = await fetch(
                    `${API_BASE_URL}/dashboard/documents/?selected_tool=${selectedTable}`,
                    {
                        headers: {
                            'Authorization': `Bearer ${user?.accessToken}`,
                        },
                    }
                );
                const fileDataJson = await fileResponse.json();

                if (!fileResponse.ok) {
                    setFiles([]);
                    setFilesLoading(false);
                    return;
                }

                console.log(fileDataJson)

                setFiles(fileDataJson);
                setFilesLoading(false);
            }
        };
        fileApiCall();
    }, [selectedTable, user?.accessToken]) 

    // fetch list of documents whenever selected table or user changes
    useEffect(() => {
        fetchFileList();
    }, [fetchFileList]);

    const [deletionState, setDeletionState] = useState<number>(0);

    const handleDelete = (name: string) => {
        // Call delete endpoint

        const deleteDoc = async (docName: string) => {

            setDeletionState(1);

            const response = await fetch(
                `${API_BASE_URL}/dashboard/documents/delete`,
                {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${user?.accessToken}`,
                    },
                    body: JSON.stringify({
                        selected_tool: selectedTable,
                        id: 0, // hate this but doc upload rework will fix this
                        name: docName
                    }),
                }
            );

            if (!response.ok)
            {
                setDeletionState(3);
                console.log("Error deleting document! " + response.status + ": " + response.statusText);
            }
            else
            {
                setDeletionState(2);
                console.log("Deleted successfully");
            }

            // refresh docs
            fetchFileList();
        };
        deleteDoc(name);
    }

    const handleDeletionModalClose = () => {
        setDeletionState(0);
    }

    const [isUploadOpen, setIsUploadOpen] = useState<boolean>(false);

    const closeUpload = () => {
        setIsUploadOpen(false);
        // requery the files
        fetchFileList();
    }


    return (
        <React.Fragment>
            <div className='p-6'>

                {/* Button & Dropdown Container */}
                <div className="flex items-center bg-slate-800 text-gray-200 border border-gray-700 rounded-t">
                    <select 
                        className="bg-slate-800 text-white px-4 py-2 mr-2 outline-none" 
                        onChange={(e) => setSelectedTable(parseInt(e.target.value, 10))} // Convert value to integer
                    >
                        {availableTables.map((tableName, index) => (
                            <option key={index} value={index}>
                                {tableName}
                            </option>
                        ))}
                    </select>

                    <button 
                        className="bg-orange-600 hover:bg-orange-700 text-white px-4 py-2 border-r border-gray-700 transition"
                        onClick={() => setIsUploadOpen(true)}
                    >
                        Upload
                    </button>
                </div>
                
                {filesLoading ? (
                    <div className="flex items-center justify-center h-32">
                        <FontAwesomeIcon
                            icon={faSpinner}
                            className="text-blue-500 text-4xl animate-spin"
                        />
                    </div>
                ) : Object.keys(files).length === 0 ? (
                    <div className="text-gray-500 pt-6 text-center">No files available.</div>
                    ) : (
                        <table className="table-auto w-full border-collapse border border-gray-700 text-white">
                            <thead>
                                <tr className="bg-slate-800 text-gray-200">
                                    <th className="px-4 py-2 border border-gray-700 text-left">File Name</th>
                                    <th className="px-4 py-2 border border-gray-700 text-center w-32">File Type</th> 
                                    <th className="px-4 py-2 border border-gray-700 text-center w-32">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.entries(files).map(([id, name], index) => (
                                    <tr 
                                        key={id} 
                                        className={`${index % 2 === 0 ? 'bg-slate-900' : 'bg-slate-800'} hover:bg-slate-700 transition py-4`}
                                    >
                                        <td className="px-4 py-2 border border-gray-700">{name[0]}</td>
                                        <td className="px-4 py-2 border border-gray-700 text-center w-32">{name[1]}</td>
                                        <td className="px-4 py-2 border border-gray-700 text-center w-32">
                                            <button
                                                onClick={() => handleDelete(name[0])}
                                                className='text-xs text-red-600 hover:text-red-400 w-4 h-4'
                                            >
                                                <FontAwesomeIcon icon={faTrashAlt} className='w-full h-full'/>
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )
                }
            </div>

            {/* Modal - Document Deletion */}
            <StatusModal
                modalState={deletionState}
                onClose={handleDeletionModalClose}
                loadingText='Deleting...'
            />
            
            {/* Modal - Document Upload */}
            {isUploadOpen && 
                <DocumentUpload
                    dataTableSelection={selectedTable}
                    dataTableName={availableTables[selectedTable]}
                    closeSelf={closeUpload}
                />
            }
        </React.Fragment>
    );
};
    
export default DocumentManagement;